import { Tab } from "../../../components";
import FundWallet from "../../../components/AirlineWallets/FundWallet/FundWallet";
import LandingBal from "../../../components/AirlineWallets/LandingBal/LandingBal";
import ParkingBal from "../../../components/AirlineWallets/ParkingBal/ParkingBal";

const AirlineWallets = () => {
  return (
    <Tab
      className="sticky top-[65px] xl:top-[72px] pl-7 z-10"
      tabs={[
        {
          title: "Landing Wallet Balance",
          render: () => <LandingBal />,
        },
        {
          title: "Parking Wallet Balance",
          render: () => <ParkingBal />,
        },
        {
          title: "Fund Wallet",
          render: () => <FundWallet />,
        },
      ]}
    />
  );
};

export default AirlineWallets;
