import { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Access, GenericLayout } from "../components";
import { AnimatePresence } from "framer-motion";

import AccountStmt from "../pages/Admin/AccountStmt/AccountStmt";
import AirlineWallets from "../pages/Admin/AirlineWallets/AirlineWallets";
import Approvals from "../pages/Admin/Approvals/Approvals";
import AuditTrail from "../pages/Admin/AuditTrail/AuditTrail";
import BillableFlight from "../pages/Admin/BillableFlight/BillableFlight";
import Invoice from "../pages/Admin/Invoice/Invoice";
import ParkingOperations from "../pages/Admin/ParkingOperations/ParkingOperations";
import ScheduledFlight from "../pages/Admin/ScheduledFlight/ScheduledFlight";
import Settlement from "../pages/Admin/Settlement/Settlement";
import Statistics from "../pages/Admin/Statistics/Statistics";
import { NotFound } from "../pages/NotFound/NotFound";
import { flighOperationRoutes } from "./flight-operations";
import { settingsRoutes } from "./settings";
import { getUserPermissions } from "../utils/localStorage";

const ServiceType = lazy(
  () => import("../pages/Landing/ServiceType/ServiceType")
);
const Login = lazy(() => import("../pages/Landing/Login/Login"));
const InitiatePwdReset = lazy(
  () => import("../pages/Landing/InitiatePwdReset/InitiatePwdReset")
);
const InitiateResetSuccess = lazy(
  () => import("../pages/Landing/InitiateResetSuccess/InitiateResetSuccess")
);
const TestPage = lazy(() => import("../pages/TestPage/TestPage"));
const QuickLinks = lazy(() => import("../pages/Landing/QuickLinks/QuickLinks"));

const AppRoutes = () => {
  const location = useLocation();
  const permission = getUserPermissions();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Login />} />
        <Route path="/initiate-pwd-reset" element={<InitiatePwdReset />} />
        <Route path="/reset-success" element={<InitiateResetSuccess />} />
        <Route path="/service-type" element={<ServiceType />} />
        <Route path="/quick-links" element={<QuickLinks />} />
        <Route path="/test-page" element={<TestPage />} />
        <Route path="admin" element={<GenericLayout />}>
          {[...flighOperationRoutes, ...settingsRoutes].map((cs) => (
            <Route
              key={cs.path}
              path={cs.path}
              element={
                <Access
                  comp={cs.comp}
                  isAllowed={permission.has(cs.permission)}
                />
              }
            />
          ))}
          <Route
            path="scheduled-flight"
            element={
              <Access
                comp={<ScheduledFlight />}
                isAllowed={permission.has("scheduled_flight")}
              />
            }
          />
          <Route
            path="billable-flights"
            element={
              <Access
                comp={<BillableFlight />}
                isAllowed={permission.has("billable_flights")}
              />
            }
          />
          <Route
            path="parking-operations"
            element={
              <Access
                comp={<ParkingOperations />}
                isAllowed={permission.has("notification")}
                // isAllowed={permission.has("parking_operations")}
              />
            }
          />
          <Route
            path="airline-wallets"
            element={
              <Access
                comp={<AirlineWallets />}
                isAllowed={permission.has("airline_wallets")}
              />
            }
          />
          <Route
            path="account-statement"
            element={
              <Access
                comp={<AccountStmt />}
                isAllowed={permission.has("account_statement")}
              />
            }
          />
          <Route
            path="settlement"
            element={
              <Access
                comp={<Settlement />}
                isAllowed={permission.has("settlement")}
              />
            }
          />
          <Route
            path="invoice-module"
            element={
              <Access
                comp={<Invoice />}
                isAllowed={permission.has("invoice_module")}
              />
            }
          />
          <Route
            path="statistics"
            element={
              <Access
                comp={<Statistics />}
                isAllowed={permission.has("statistics")}
              />
            }
          />
          <Route
            path="audit-trail"
            element={
              <Access
                comp={<AuditTrail />}
                isAllowed={permission.has("audit_trail")}
              />
            }
          />
          <Route
            path="approvals"
            element={
              <Access
                comp={<Approvals />}
                isAllowed={permission.has("maker_checker")}
              />
            }
          />
          {/* <Route path="account" element={<Account />} /> */}
        </Route>
        <Route path="*" element={<NotFound isPublicRoute={true} />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
