import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

type UIState = {
  toggleSidebar: boolean;
  showDropDown: null;
  currentTab: string;
  currentTabIndex?: number;
  leaveOpen?: boolean;
};

// Define the initial state using that type
const initialState: UIState = {
  toggleSidebar: false,
  showDropDown: null,
  currentTab: "",
  currentTabIndex: -1,
  leaveOpen: true,
};

export const counterSlice = createSlice({
  name: "counter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setShowSidebar: (state, action) => {
      state.toggleSidebar = action.payload;
    },
    setShowDropdown: (state, action) => {
      state.showDropDown = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setCurrentTabIndex: (state, action) => {
      state.currentTabIndex = action.payload;
    },
    setLeaveOpen: (state, action) => {
      state.leaveOpen = action.payload;
    },
  },
});

export const {
  setShowSidebar,
  setShowDropdown,
  setCurrentTab,
  setCurrentTabIndex,
  setLeaveOpen,
} = counterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value;

export default counterSlice.reducer;
