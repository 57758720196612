import * as yup from "yup";

export const schema = yup.object().shape({
  flightOperator: yup.string().required("Flight Operator name is required"),
  regNumber: yup.string().required("Registration Number is required"),
  aircraftType: yup.string().required("Aircraft Type is required"),
  seatCapacity: yup.string().required("Seat capacity is required"),
  aircraftWeight: yup.string().required("Aircraft weight is required"),
});

export interface IFormInput {
  startDate: string;
  regNumber: string;
  aircraftType: string;
  seatCapacity: string;
  aircraftWeight: string;
}
