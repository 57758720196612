import { FC } from "react";

export const BackCaret: FC<{ className: string; onClick?: Function }> = ({
  className,
  onClick,
}) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={() => onClick && onClick()}
    >
      <path
        d="M16.0903 18L10.0948 12.0711C10.0552 12.032 10.0552 11.968 10.0948 11.9289L16.0903 6"
        className={className}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
