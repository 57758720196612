import * as yup from "yup";

export const schema = yup.object().shape({
  startDate: yup.string().required("Arrival airport is required"),
  endDate: yup.string().required("Departure airport is required"),
  arrivalAirport: yup.string().required("Estimated Arrival time is required"),
  departureAirport: yup.string().required("Flight operator is required"),
});

export interface IFormInput {
  startDate: string;
  endDate: string;
  arrivalAirport: string;
  DepartureAirport: string;
}
