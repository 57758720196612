import { FC, ReactNode } from "react";

export const NewCard: FC<{
  icon: ReactNode;
  title: string;
  className?: string;
  count?: string;
  showCountLabel?: boolean;
}> = ({ icon, title, className, count, showCountLabel = false }) => {
  return (
    <div className="pt-6">
      <div
        className={`w-[243px] rounded-[10px] flex flex-col justify-center p-4 border-b-8 gap-6 bg-gray-100 border-b-green-800 ${className}`}
      >
        <h6 className="text-xs font-semibold">{title}</h6>
        <div className="flex justify-between items-center">
          {icon}
          <div>
            <span className="font-semibold text-2xl">{count}</span>
            {showCountLabel && <span className="text-xs ml-2">Count</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
