import React, { FC } from "react";

export const PlaneLevelIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3333 11.9118L31.6667 20.3335V23.6668L18.3333 19.4568V28.3902L23.3333 31.1668V33.6668L15.8333 32.0002L8.33333 33.6668V31.1668L13.3333 28.3885V19.4552L0 23.6668V20.3335L13.3333 11.9118V2.8335C13.3333 2.17045 13.5967 1.53457 14.0656 1.06573C14.5344 0.596888 15.1703 0.333496 15.8333 0.333496C16.4964 0.333496 17.1323 0.596888 17.6011 1.06573C18.0699 1.53457 18.3333 2.17045 18.3333 2.8335V11.9118Z"
        fill={className}
      />
    </svg>
  );
};
