import { devInstance } from "../../axios/axiosInstance";
const MODE = process.env.NODE_ENV === "development";

export const getAirlines = async (payload: any) => {
    const url ='/Airline/GetAirlines'
    const response = await devInstance.post(url, payload)
    return response
}

export const createAirline = async (payload: any) => {
    const url ='/Airline/CreateAirline'
    const response = await devInstance.post(url, payload)
    return response
}

export const downloadAirline = async (payload: any) => {
    const url ='/Airline/Download'
    const response = await devInstance.post(url, payload)
    return response
}

export const getAircrafts = async (payload: any) => {
    const url ='/Aircraft/GetAircrafts'
    const response = await devInstance.post(url, payload)
    return response
}

export const getAirportBase = async (countryCode: string) => {
    const url ='/Airport/ByCounty'
    const response = await devInstance.get(`${url}?countryCode=${countryCode}`)
    return response
}

export const createAircraft = async (payload: any) => {
    const url ='/Aircraft/CreateAircraft'
    const response = await devInstance.post(url, payload)
    return response
}

export const downloadAircraft = async (payload: any) => {
    const url ='/Aircraft/Download'
    const response = await devInstance.post(url, payload)
    return response
}
