import { Tab } from "../../../components";
import LandingSettlement from "../../../components/Settlement/LandingSettlement/LandingSettlement";
import ParkingSettlement from "../../../components/Settlement/ParkingSettlement/ParkingSettlement";

const Settlement = () => {
  return (
    <Tab
      className="sticky top-[65px] xl:top-[72px] pl-7 z-10"
      tabs={[
        {
          title: "Landing Settlement",
          render: () => <LandingSettlement />,
        },
        {
          title: "Parking Settlement",
          render: () => <ParkingSettlement />,
        },
      ]}
    />
  );
};

export default Settlement;
