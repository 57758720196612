export type TableData = {
    id?: number;
    createdDate?: string;
    name: string;
    homeCountry: string;
    airportBase?: string;
    category: number;
    ebipsain?: string;
    fleetNumber: number;
    itemDetail?: any
  };
  
  export const tableHead: { name: keyof TableData; displayName: any }[] = [
    { name: "id", displayName: "S/N" },
    { name: "createdDate", displayName: "Created Date" },
    { name: "name", displayName: "Airline Name" },
    { name: "homeCountry", displayName: "Home Country" },
    { name: "airportBase", displayName: "Airport Base" },
    { name: "category", displayName: "Category" },
    { name: "ebipsain", displayName: "Customer EBIPS AIN" },
    { name: "fleetNumber", displayName: "Fleet Number" },
    { name: "itemDetail", displayName: "" },
  ];
  