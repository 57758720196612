import AppRoutes from "./routes/PublicRoutes";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { FallbackBg } from "./components";

function App() {
  // eslint-disable-next-line no-restricted-globals
  console.log("screen", screen.height);

  return (
    <BrowserRouter>
      <Suspense fallback={<FallbackBg />}>
        <AppRoutes />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
