export type Rate = {
  id?: number;
  createdDate:string;
  landingNightRate: string;
  landingDayRate: string;
  parkingRate?: string;
  isCurrent: boolean;
  currencyCode: string;
  currency: number;
};

export const tableHead: { name: keyof Rate; displayName: any }[] = [
  { name: "id", displayName: "S/N" },
  { name: "createdDate", displayName: "Date" },
  { name: "landingNightRate", displayName: "Night Landing" },
  { name: "landingDayRate", displayName: "Rate" },
  { name: "parkingRate", displayName: "Parking" },
];
