import React, { FC } from "react";

export const StatisticsIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.5 13.9998H1.33333V7.33317H5.5V13.9998ZM10.5 13.9998H6.33333V3.99984H10.5V13.9998ZM15.5 13.9998H11.3333V0.666504H15.5V13.9998ZM16.3333 17.3332H0.5V15.6665H16.3333V17.3332Z"
        fill={className}
      />
    </svg>
  );
};
