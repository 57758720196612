import React, { useState } from "react";
// import BackDrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from "classnames";

export interface TableField<T> {
  name: keyof T;
  displayName?: string;
}

export interface tableData<T> {
  items: T[];
}

export type FieldBuilder<T, D> = (
  field: TableField<T>,
  data: D,
  row: number,
  column: number
) => React.ReactNode;

export interface TableProps<T, D> {
  fields: TableField<T>[];
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numSelected?: number;
  checked?: boolean;
  tableData: D[];
  noDataMessage?: string;
  builder: FieldBuilder<T, D>;
  isLoading?: boolean;
  startColumn?: number;
  startRow?: number;
  columns?: number;
  rows?: number;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

function Table<TField, TData = TField>({
  checked = false,
  tableData,
  fields,
  builder,
  isLoading = false,
  columns,
  rows,
  after,
  noDataMessage = "No records found to display",
  startColumn = 0,
  startRow = 0,
}: TableProps<TField, TData & { id?: string | Number }>) {
  const numberOfColumns = columns || fields.length;
  const numberOfRows = tableData?.length > 0 ? rows || tableData?.length : 0;
  const columnElements: React.ReactNode[] = [];
  const [isChecked] = useState<boolean>(checked);
  const [selected, setSelected] = useState<any[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n, i) => n.id!);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  for (let i = startRow; i < startRow + numberOfRows; i += 1) {
    const data = tableData[i];
    const rowElements: React.ReactNode[] = [];

    for (let j = startColumn; j < startColumn + numberOfColumns; j += 1) {
      const field = fields[j];
      rowElements.push(
        <td
          key={j}
          className="border-b align-middle text-center p-4 xl:p-5 border-gray-300 text-[11px] xl:text-xs"
        >
          {builder(field, data, i, j)}
        </td>
      );
    }

    const isSelected = selected.indexOf(data.id!) !== -1;

    columnElements.push(
      <tr key={i} className="h-auto border-b  ">
        {isChecked && (
          <td className="h-auto  ">
            <input
              type="checkbox"
              onChange={(event) =>
                handleChange(event, data.id as unknown as any)
              }
              checked={isSelected}
              name="check"
              className="checkbox"
            />
          </td>
        )}
        {rowElements}
      </tr>
    );
  }

  return (
    <div className="min-h-[200px] w-full overflow-x-auto">
      <table className="w-full table-auto border-collapse  ">
        <thead className="bg-black-900     text-gray-100">
          <tr className="">
            {isChecked && (
              <th className="">
                <input
                  type="checkbox"
                  name="check"
                  className="checkbox"
                  checked={
                    tableData.length > 0 && selected.length === tableData.length
                  }
                  onChange={handleSelectAllClick}
                />
              </th>
            )}
            {fields.map((data) => (
              <th
                scope="col"
                key={Math.random().toString()}
                className="py-5  first:rounded-tl-2xl last:rounded-tr-2xl text-[11px] xl:text-xs"
              >
                {data.displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tbody bg-gray-100 min-h-[400px]">
          {columnElements}
        </tbody>
      </table>
      {/* <BackDrop className="table-loading-back-drop" open={!tableData?.length && isLoading}>
        <CircularProgress size={25} />
      </BackDrop> */}
      <div
        className={classNames(
          "min-h-[350px] flex items-center justify-center",
          { hidden: columnElements?.length > 0 }
        )}
      >
        <div className={classNames({ hidden: isLoading })}>{noDataMessage}</div>
        <div
          className={classNames({
            hidden: !isLoading && columnElements.length === 0,
          })}
        >
          Loading...
        </div>
      </div>
      <div className="">{after || null}</div>
    </div>
  );
}

export default Table;
