import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { FC, ReactNode } from "react";
import { Controller } from "react-hook-form";

export const MaterialSelect: FC<{
  value: any;
  onChange: any;
  options: any[];
  label: ReactNode;
  containerClass?: string;
}> = ({ value, onChange, options, label, containerClass = "w-40" }) => {
  return (
    <FormControl
      variant="standard"
      sx={{ m: 0 }}
      className={`w-full ${containerClass}`}
      size="small"
    >
      <InputLabel shrink={true} id="demo-simple-select-standard-label">
        {label}
      </InputLabel>

      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.map((opt) => (
          <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const MaterialSelect2: FC<{
  control: any;
  name: string;
  containerClass?: string;
  options: any[];
  label: ReactNode;
}> = ({ control, name, label, containerClass, options }) => {
  return (
    <FormControl
      variant="standard"
      sx={{ m: 0 }}
      className={`w-full ${containerClass}`}
      size="small"
    >
      <InputLabel
        className="text-gray-300"
        id="demo-simple-select-standard-label"
        shrink
      >
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            {...field}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label={label}
          >
            {options.map((opt, index) => (
              <MenuItem key={index} value={opt?.value}>
                {opt?.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};
