import { MutableRefObject, useRef } from "react";
import Menu from "../Menu";
import logo from "../../../../../assets/img/faan-logo.png";
import { useOnClickOutside } from "../../../../../hooks/useOnClickOutside";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/hooks";
import { setShowSidebar } from "../../../../../redux/features/ui-slice";

const MobileSidebar = () => {
  const dispatch = useAppDispatch();
  const { toggleSidebar } = useAppSelector((state) => state.ui);
  const ref: MutableRefObject<undefined> = useRef();
  useOnClickOutside(ref, () => dispatch(setShowSidebar(false)));

  return (
    <aside
      className={`w-[240px] bg-green-900 text-green-400 min-h-screen fixed top-0  block lg:hidden scrollbar-hide z-50 transition-all ease-in-out ${
        toggleSidebar ? "left-0" : "-left-[240px]"
      }`}
      //@ts-ignore
      ref={ref}
    >
      <div className=" p-6 flex justify-center items-center">
        <div className="w-[84px] h-[30px]">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <Menu />
    </aside>
  );
};

export default MobileSidebar;
