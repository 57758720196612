import { FC } from "react";
import { Control, UseFormHandleSubmit } from "react-hook-form";
import { Button } from "../../..";
import { ErrorSummary } from "../../../../utils/errorSummary";
import { MaterialInput2 } from "../../../UIKit/Input/MaterialInput";
import { MaterialSelect2 } from "../../../UIKit/Select/MaterialSelect";
import { IFormInput } from "../model";

export const SearchForm: FC<{
  control: Control<IFormInput, any>;
  handleSubmit: UseFormHandleSubmit<IFormInput>;
  refetchApprovals: any;
  errors: any;
  isRefetching: boolean;
}> = ({ control, handleSubmit, refetchApprovals, errors, isRefetching }) => {
  const searchHandler = (data: any) => {
    console.log("submitted Data", data);
    refetchApprovals();
  };

  return (
    <>
      <div className="mb-3">
        <ErrorSummary errors={errors} />
      </div>

      <form
        className="flex flex-wrap items-end gap-6 w-full"
        onSubmit={handleSubmit(searchHandler)}
      >
        <MaterialInput2
          control={control}
          name="startDate"
          label="Start Date"
          type="date"
          containerClass="w-40"
        />

        <MaterialInput2
          control={control}
          name="endDate"
          label="End Date"
          type="date"
          containerClass="w-40"
        />

        <MaterialSelect2
          containerClass="w-40"
          control={control}
          name="arrivalAirport"
          label={<span className="text-gray-300">Arrival Airport</span>}
          options={[
            { label: "", value: null },
            { label: "Murtala Muhammed International Airport", value: "MMA" },
            { label: "Nnamdi Azikiwe International Airport", value: "NAA" },
          ]}
        />

        <MaterialSelect2
          containerClass="w-40"
          control={control}
          name="departureAirport"
          label={<span className="text-gray-300">Departure Airport</span>}
          options={[
            { label: "Murtala Muhammed International Airport", value: "MMA" },
            { label: "Nnamdi Azikiwe International Airport", value: "NAA" },
          ]}
        />

        <Button
          label="Search"
          className="w-40"
          variant="filled"
          isLoading={isRefetching}
        />
      </form>
    </>
  );
};
