
import { AxiosError } from "axios";
import moment from "moment";
import { FC, useEffect } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Button, SideModal } from "../../../../../components";

export const AirlineDetail: FC<{
  showSideModal: boolean;
  setShowSideModal: any;
  entryData: any;
}> = ({ showSideModal, setShowSideModal, entryData }) => {
  
  return (
    <SideModal isOpen={showSideModal} setIsOpen={setShowSideModal}>
      <div className="px-5">
        <h3 className="font-semibold mt-4">Additional Information</h3>
        <hr className="bg-gray-300 h-[2px] mb-8 mt-4" />
        <div className="flex flex-col mt-12">
          <div className="flex justify-between gap-4 mt-4">
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">Created Date</p>
              <p className="text-[16px] font-semibold mt-3">{entryData && entryData?.createdDate}</p>
            </div>
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">Airline Name</p>
              <p className="text-[16px] font-semibold mt-3">{entryData && entryData?.name}</p>
            </div>
          </div>
          <div className="flex justify-between gap-4 mt-6">
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">
                Home Country
              </p>
              <p className="text-[16px] font-semibold mt-3">{entryData && entryData?.homeCountry}</p>
            </div>
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">Category</p>
              <p className="text-[16px] font-semibold mt-3">
                {entryData && entryData?.categoryName}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-4 mt-6">
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">
                Customer EBIPS
              </p>
              <p className="text-[16px] font-semibold mt-3">{entryData && entryData?.ebipsain}</p>
            </div>
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">
                Fleet Number
              </p>
              <p className="text-[16px] font-semibold mt-3">
              {entryData && entryData?.ebipsain}
              </p>
            </div>
          </div>
          <div className="w-full mt-12">
            <p className="font-light text-[14px] text-[#848484]">Airline Airport Base</p>
            <p className="text-[16px] font-semibold mt-3">
              {entryData && entryData?.airportBase}
            </p>
          </div>
        </div>
      </div>
    </SideModal>
  );
};

