import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Button, Input, Select, SideModal } from "../../../..";
import useAirportBase from "../../../../../hooks/useAirportBase";
import { ErrorSummary } from "../../../../../utils/errorSummary";
import { getSavedState } from "../../../../../utils/localStorage";
import { createMakerChecker } from "../../../../../utils/services/settings/makerchecker";
import { schema } from "./schema";

export const NewAirline: FC<{
  showSideModal: boolean;
  setShowSideModal: any;
  refetch: any;
}> = ({ showSideModal, setShowSideModal, refetch }) => {
  const savedState = getSavedState("LNPUser");
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { airportBase, getAllAirportBase, getCountryCode } = useAirportBase();

  const {
      mutateAsync,
      isLoading,
      isError,
      error,
      data,
  } = useMutation(createMakerChecker, {
      onSuccess: ({ data }) => {
          setShowSideModal(false)
          toast.success("New Airline Added Sent to Maker checker for Approval");
          refetch()
      },
      onError: (error: AxiosError) => {
          setShowSideModal(false)
          toast.error('Create Airline unsuccessful')
      },
  })

  const onSubmit = (data: any) => {
    const airlineData = {
      name: data?.airlineName,
      homeCountry: data?.homeCountry,
      category: parseInt(data?.category),
      ebipsain: data?.ebipsAIN,
      airlineBases: data?.airlineAirportBase?.map((ele: any) => {
        return {
          id: 0,
          airlineId: parseInt(ele?.value),
          airportCode: ele?.label
        }
      })
    }
    const MODE = process.env.NODE_ENV === "development";
    const url = '/Airline/CreateAirline';
    mutateAsync({
      //@ts-ignore
      URL: MODE ? `${process.env.REACT_APP_API_URL}${url}` : `${window.env.passerelleUrl}/api${url}`,
      AppId: MODE
            //@ts-ignore
      ? parseInt(process.env.REACT_APP_MAKER_CHECKER_ID)
      : //@ts-ignore
        window.env.makerCheckerAppId,
      CreatedBy: savedState?.userName,
      Payload: JSON.stringify(airlineData),
      EntityTypeId: 26,
      // UserRole: savedState?.userRole,
      // PhoneNumber: savedState?.phoneNumber,
      // FullName: savedState?.fullName,
    });
  };
  let currentValues = watch();

  useEffect(() => {
    if (currentValues?.homeCountry) {
      getCountryCode(currentValues?.homeCountry)
    }
  }, [currentValues?.homeCountry]);

  useEffect(() => {
    getAllAirportBase()
  }, []);

  return (
    <SideModal isOpen={showSideModal} setIsOpen={setShowSideModal}>
      <form className="p-6" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="font-semibold">Add Airline</h3>

        <hr className="bg-gray-300 h-[2px] mb-8 mt-4" />

        <div className="mb-3">
          <ErrorSummary errors={errors} />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Airline Name"
            register={register("airlineName", {
              required: true,
            })}
            error={errors?.airlineName}
          />
          <Select
            label="Home Country"
            containerClass="w-full"
            name="homeCountry"
            options={[
              { label: "", value: "" },
              { label: "Nigeria", value: "NG" },
              { label: "Ghana", value: "GN" },
            ]}
            control={control}
            error={errors.homeCountry}
          />
          <Select
            label="Category"
            containerClass="w-full"
            name="category"
            options={[
              { label: "Domestic ", value: "1" },
              { label: "Registered ", value: "2" },
              { label: "UnRegistered  ", value: "3" },
            ]}
            control={control}
            error={errors.category}
          />
          <Input
            label="Customer EBIPS AIN"
            register={register("ebipsAIN", {
              required: true,
            })}
            error={errors?.ebipsAIN}
          />
          <Select
            containerClass="w-full"
            name="airlineAirportBase"
            label="Airline Airport Base"
            isMulti={true}
            options={airportBase?.map(
              (ele: { id: any; name: any }) => {
                  return {
                      value: ele.id,
                      label: ele.name,
                  }
              }
            )}
            control={control}
            error={errors.airlineAirportBase}
          />
        </div>

        <Button
          label="Add Airline"
          className="w-[242px] mt-14"
          variant="filled"
          isLoading={isLoading}
        />
      </form>
    </SideModal>
  );
};
