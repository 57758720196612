import React, { ReactElement } from "react";

interface IDrawer {
  isOpen: boolean;
  setIsOpen: any;
  children: React.ReactNode;
}

export function SideModal({
  isOpen,
  setIsOpen,
  children,
}: IDrawer): ReactElement {
  return (
    <div
      className={
        " fixed  overflow-hidden z-30 bg-green-900 inset-0 transform ease-in-out " +
        (isOpen
          ? "duration-500 translate-x-0 bg-opacity-[0.6]"
          : " transition-all delay-100 opacity-0 translate-x-full  ")
      }
    >
      <div
        className={
          " w-screen overflow-hidden max-w-lg right-0 absolute bg-white h-full  delay-400 duration-500 ease-in-out transition-all transform z-40 overflow-y-scroll  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        {children}
      </div>
      <div
        className="w-screen h-full cursor-pointer relative "
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </div>
  );
}
