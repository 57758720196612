import * as yup from "yup";

export const schema = yup.object().shape({
  startDate: yup.string().required("Start date is required"),
  endDate: yup.string().required("End date is required"),
  arrivalAirport: yup.string().required("Arrival airport is required"),
  departureAirport: yup.string().required("Departure airport is required"),
});

export interface IFormInput {
  startDate: string;
}

export type TableData = {
  sn?: number;
  entity?: string;
  createdBy: string;
  createdDate: string;
  approvedBy: string;
  status?: string;
};
export const tableHead: { name: keyof TableData; displayName: any }[] = [
  { name: "sn", displayName: "S/N" },
  { name: "entity", displayName: "Entity" },
  { name: "createdBy", displayName: "Created By" },
  { name: "createdDate", displayName: "Created Date" },
  { name: "approvedBy", displayName: "Approved By" },
  { name: "status", displayName: "Status" },
];
