import { createRef, ReactElement, useTransition } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/hooks";
import {
  setShowDropdown,
  setShowSidebar,
} from "../../../../../redux/features/ui-slice";
import { IMenuProps } from "./model";
import { CaretDown } from "../../../../../assets/icon/CaretDown";
import { CaretRight } from "../../../../../assets/icon/CaretRight";

function MenuItem({
  name,
  className,
  Icon,
  menuAction,
  path,
  currentTabHandler,
  options,
  textColor,
}: IMenuProps): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [, startTransition] = useTransition();

  const currentTab = useAppSelector((state) => state.ui.currentTab);
  const formLoc = location.pathname;
  // const formPath = path?.split("/")[0];
  const currentTabRef = createRef<HTMLInputElement>();

  const handleClick = (path: string) => {
    startTransition(() => {
      navigate(`${path}`, { replace: true });
      dispatch(setShowDropdown(null));
      dispatch(setShowSidebar(false));
    });
  };

  return (
    <li
      className={`list-none cursor-pointer mx-3 sticky  ${
        options?.length && currentTab === name
          ? "text-white bg-green-550 bottom-[40px]"
          : "text-green-650"
      } ${className}`}
      //@ts-ignore
      ref={currentTabRef}
    >
      <div
        className={`flex items-center h-[40px] xl:h-[50px] px-5 gap-4 ${
          path === formLoc && !options?.length && "bg-green-600 text-white"
        } `}
        onClick={(e) => {
          e.preventDefault();
          startTransition(() => {
            menuAction && menuAction();
            currentTabHandler(name);
            if (!options?.length) {
              dispatch(setShowDropdown(null));
              dispatch(setShowSidebar(false));
            }
          });
        }}
      >
        {Icon && (
          <Icon
            className={`${
              formLoc === path
                ? "w-[15px] h-[15px] stroke-white fill-white"
                : "w-[15px] h-[15px] stroke-green-600 fill-green-600 group-hover:fill-white group-hover:stroke-white"
            }`}
          />
        )}
        <div className={`flex font-Inter items-center justify-between w-full `}>
          <span className={`text-xs xl:text-sm  ${textColor ? textColor : ""}`}>
            {name}
          </span>
          {options?.length && currentTab === name ? (
            <CaretDown />
          ) : (
            options?.length && <CaretRight />
          )}
        </div>
      </div>
      <>
        {currentTab === name &&
          options?.map((item) => (
            <div
              className={`text-xs xl:text-sm  h-[40px] xl:h-[45px] pl-12 pr-3 flex flex-col justify-center  ${
                location.pathname === item?.url
                  ? "bg-green-600 text-white"
                  : "text-green-650"
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleClick(item?.url);
              }}
              key={item.url}
            >
              {item?.name}
            </div>
          ))}
      </>
    </li>
  );
}

export default MenuItem;
