import { FC, ReactNode } from "react";
import { Spinner } from "../Spinner/Spinner";

export const Button: FC<{
  label: ReactNode;
  className?: string;
  onClick?: any;
  size?: string;
  isLoading?: boolean;
  variant?: "outlined" | "filled" |"dynamic";
  isDisabled?: boolean
}> = ({ label, className = "xl:h-[45px]", onClick, isLoading, variant, isDisabled }) => {
  if (variant === "outlined") {
    return (
      <button
        className={`text-[10px] xl:text-xs flex items-center justify-center text-[#219653] px-3   border border-[#219653] rounded-[10px] cursor-pointer h-[35px] w-[243px]
         ${className}`}
        onClick={onClick}
      >
        {isLoading ? <Spinner /> : label}
      </button>
    );
  } else if (variant === "filled") {
    return (
      <button
        onClick={onClick}
        className={`text-[10px] xl:text-xs bg-green-600 text-white rounded-[10px] h-[35px]  px-4 shadow
         ${className}`}
      >
        {isLoading ? <Spinner /> : label}
      </button>
    );
  } else if (variant === "dynamic") {
    return (
      <button
        onClick={onClick}
        className={`text-[10px] xl:text-xs text-white rounded-[10px] h-[35px]  px-4 shadow
         ${className} ${isDisabled ? 'cursor-not-allowed' : ''}`}
         disabled={isDisabled}
      >
        {isLoading ? <Spinner /> : label}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`text-[10px] xl:text-xs rounded-[10px] h-[35px]  px-4 shadow
       ${className}`}
    >
      {isLoading ? <Spinner /> : label}
    </button>
  );
};
