export const getStatus = (stat: string | undefined) => {
  const statusObj: { [key: string]: string } = {};
  statusObj["approved"] = "text-green-600";
  statusObj["rejected"] = "text-red-700";
  statusObj["pending"] = "text-yellow-600";

  if (stat) {
    return statusObj[stat];
  }
  return "";
};
