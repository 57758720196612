import * as yup from "yup";

export const schema = yup.object().shape({
  airline: yup.string().required("Airline is required"),
  aircraftType: yup.string().required("AircraftType is required"),
  startDate: yup.string().required("Start date is required"),
  registrationNumber: yup.string().required("Registration Number is required"),
});

export interface IFormInput {
  startDate: string;
  airline: string;
  aircraftType: string;
  registrationNumber: string;
}
