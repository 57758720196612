import { ReactElement } from "react";
import logo from "../../../../assets/img/faan-logo.png";
import Menu from "./Menu";

function AdminSidebar(): ReactElement {
  return (
    <aside className="w-[240px] lg:w-[21%] bg-green-900 text-green-400 min-h-screen fixed top-0 left-0 hidden lg:block scrollbar-hide">
      <div className=" p-6 flex justify-center items-center">
        <div className="w-[84px] h-[30px]">
          <img src={logo} alt="logo" />
        </div>
      </div>

      <Menu />
    </aside>
  );
}

export default AdminSidebar;
