import { AxiosError, AxiosResponse } from "axios";
import { SetStateAction, useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Button,
  Input,
  PaginationComponent,
  Select,
  Table,
} from "../../../components";
import { MaterialSelect } from "../../../components";
import DownloadIcon from "../../../assets/img/icons/download-icon.svg";
import { motion } from "framer-motion";
import { MaterialInput } from "../../../components/UIKit/Input/MaterialInput";
import { approveMakerChecker, rejectMakerChecker, searchMakerChecker } from "../../../utils/services/settings/makerchecker";
import { getSavedState } from "../../../utils/localStorage";
import { TableData, tableHead } from "./model";
import moment from "moment";
import useEntities from "../../../hooks/useEntities";
import { DetailsIcon } from "../../../assets/icon/eye";
import { RequestDetail } from "./EntryDetails/details";
import { toast } from "react-toastify";

const Approvals = () => {
  const [totalCounts, settotalCounts] = useState(20);
  const [pageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [approvals, setApprovals] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setDetailsSideModal] = useState(false);
  const [modalDetail, setModalDetal] = useState({})

  const { entities, getAllEntities } = useEntities();

  const savedState = getSavedState("LNPUser");

  const payload = {
    AppId: 13,
    UserName: savedState.userName,
    from: 1,
    Size: 20,
    RoleName: 'VGG Admin'
  };

  const handleapprove = () => {
    approveFunc({
      //@ts-ignore
      MakeCheckerId: modalDetail?.Id,
      //@ts-ignore
      UserName: savedState?.userName,
      Comment: 'hello world',
      ApprovedBy: savedState?.userName
    })
  }

  const handlereject = () => {
    rejectFunc({
      //@ts-ignore
      MakeCheckerId: modalDetail?.Id,
      //@ts-ignore
      UserName: savedState?.userName,
      Comment: 'hello world',
    })
  }



  const [age, setAge] = useState("1");

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    getAllEntities()
  }, [])

  const {
    mutateAsync: rejectFunc,
    isLoading: isRejecting,
    data: rejectData,
} = useMutation(rejectMakerChecker, {
    onSuccess: ({ data }) => {
      toast.success('Rejected successfully');
      setDetailsSideModal(false)
      refetchEntries()
    },
    onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error?.response?.data || JSON.parse(error?.response?.data[0])['message'])
    },
  })

  const {
    mutateAsync: approveFunc,
    isLoading: isAppprovng,
    data,
} = useMutation(approveMakerChecker, {
    onSuccess: ({ data }) => {
        toast.success('Approved successfully');
        setDetailsSideModal(false)
        refetchEntries()
    },
    onError: (error: AxiosError) => {
        //@ts-ignore
        if (error?.response?.data) {
          //@ts-ignore
          toast.error(error?.response?.data)
          // return;
        }
        //@ts-ignore
        if (error?.response?.data[0]) {
          //@ts-ignore
          toast.error(error?.response?.data[0])
          // return;
        }
    },
  })

  const { isLoading, isRefetching, isFetching, refetch: refetchEntries } = useQuery(
    ["fetchMakerChecker"],
    () => searchMakerChecker(payload),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response: AxiosResponse) => {
        const statusEnum = {
          0: {title: "Pending", color: '#E6A900'},
          1: {title: "Accepted", color: '#219653'},
          2: {title: "Rejected", color: '#CA1B1B'},
        }
        const result = response.data?.MakeCheck.map((item: any, idx:number) => {
          // @ts-ignore
          const entity = entities && entities.filter((ele: any) => ele?.EntityTypeId == item?.EntityTypeId)[0]?.EntityType;
          const newItem =  {
            ...item,
            sn: idx + 1,
            approvedBy: item?.ApprovedBy || 'N/A',
            createdBy: item?.CreatedBy || 'N/A',
            createdDate: moment(item?.CreatedDate).format('DD/MM/YYYY'),
            // @ts-ignore
            status: <span className={`text-[${statusEnum[item?.StatusId]?.color}]`}>{statusEnum[`${item?.StatusId}`]?.title}</span>,
            entity,
          }

          return {
            ...newItem,
            itemDetail: <DetailsIcon 
                          showDetailsModal={showDetailsModal} 
                          setDetailsSideModal={setDetailsSideModal} 
                          onClick={() => {
                              setDetailsSideModal(true)
                              setModalDetal(newItem)
                          }} 
                        />
          }
        })
        setApprovals(result);
        settotalCounts(response.data?.totalCount);
      },
    }
  );

  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pl-7 xl:pl-10 py-3 pr-7">
        <div className="flex justify-between">
          <div className="flex flex-wrap items-end gap-6 w-full">
            <MaterialSelect
              value={age}
              onChange={handleChange}
              label="Entity Type"
              options={[
                { label: "", value: null },
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />

            <MaterialSelect
              value={age}
              onChange={handleChange}
              label={<span className="text-gray-300">User Role</span>}
              options={[
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />
            <MaterialSelect
              value={age}
              onChange={handleChange}
              label={<span className="text-gray-300">Created Date</span>}
              options={[
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />

            <MaterialSelect
              value={age}
              onChange={handleChange}
              label={<span className="text-gray-300">User's Email</span>}
              options={[
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />
            <MaterialInput
              id="date"
              label="Start Date"
              type="date"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{ width: 160 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MaterialInput
              id="date"
              label="End Date"
              type="date"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{ width: 160 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button label="Search" className="w-40" variant="filled" />
          </div>
        </div>

        <div className="bg-gray-100 mt-14">
          <div className="flex items-center justify-between  p-2">
            <div className="flex items-center py-1 px-3 gap-4">
              <h3 className="text-sm text-black-900 font-semibold min-w-fit">
                Approvals Record
              </h3>
            </div>

            <Button
              variant="outlined"
              className="h-[38px]"
              label={
                <div className="flex gap-3">
                  <img src={DownloadIcon} alt="" />
                  Download Report
                </div>
              }
            />
          </div>

          <Table<TableData>
            fields={tableHead}
            isLoading={isLoading || isFetching || isRefetching}
            tableData={approvals || []}
            builder={(field, data) => {
              return data[field.name];
            }}
          />
        </div>
        <div>
          {approvals?.length > 0 && (
            <PaginationComponent
              totalCounts={totalCounts}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              showOnSmallScreen={false}
            />
          )}
        </div>
      </div>

      <RequestDetail
        setShowSideModal={setDetailsSideModal}
        showSideModal={showDetailsModal}
        entryData={modalDetail}
        handleapprove={handleapprove}
        handlereject={handlereject}
        isAppprovng={isAppprovng}
        isRejecting={isRejecting}
      />
    
    </motion.div>
  );
};

export default Approvals;
