import { AxiosResponse } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Button, PaginationComponent, Table } from "../../../components";
import DownloadIcon from "../../../assets/img/icons/download-icon.svg";
import { fetchApprovals } from "../../../utils/services/approvals";
import { NewCard } from "../../../components/Card/NewCard";
import { FlyIcon } from "../../../assets/icon/flyIcon";
import { motion } from "framer-motion";
import { SearchForm } from "./SearchForm/SearchForm";
import { useForm } from "react-hook-form";
import { IFormInput, schema } from "./model";
import { yupResolver } from "@hookform/resolvers/yup";
import { getStatus } from "../../../utils/getStatus";
type TableData = {
  sn?: number;
  entity?: string;
  createdBy: string;
  createdDate: string;
  approvedBy: string;
  status?: string;
};
const tableHead: { name: keyof TableData; displayName: any }[] = [
  { name: "sn", displayName: "S/N" },
  { name: "entity", displayName: "Entity" },
  { name: "createdBy", displayName: "Created By" },
  { name: "createdDate", displayName: "Created Date" },
  { name: "approvedBy", displayName: "Approved By" },
  { name: "status", displayName: "Status" },
];

const LandingSettlement = () => {
  const [totalCounts, settotalCounts] = useState(20);
  const [pageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [rates, setRates] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const {
    isLoading,
    isRefetching,
    isFetching,
    refetch: refetchApprovals,
  } = useQuery(["fetchApprovals"], () => fetchApprovals(), {
    enabled: true,
    retry: 2,
    onSuccess: (response: AxiosResponse) => {
      setRates(response.data?.resultList);
      settotalCounts(response.data?.totalCount);
    },
  });

  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pl-7 xl:pl-10 py-3 pr-7">
        <div className="flex gap-4 mb-12">
          <NewCard
            icon={<FlyIcon className="fill-blue-800 w-5" />}
            title="Airline Wallet"
            className="border-b-blue-800"
            count="N 200,900"
          />
          <NewCard
            icon={<FlyIcon className=" fill-purple-900 w-5" />}
            title="Airline Wallet"
            className="border-b-purple-900"
            count="N 200,900"
          />
        </div>
        <SearchForm
          errors={errors}
          handleSubmit={handleSubmit}
          refetchApprovals={refetchApprovals}
          control={control}
          isRefetching={isRefetching}
        />

        <div className="bg-gray-100 mt-14">
          <div className="flex items-center justify-between  p-2">
            <div className="flex items-center py-1 px-3 gap-4">
              <h3 className="text-sm text-black-900 font-semibold min-w-fit">
                Landing Account Record
              </h3>
            </div>

            <Button
              variant="outlined"
              className="h-[38px]"
              label={
                <div className="flex gap-3">
                  <img src={DownloadIcon} alt="" />
                  Download Report
                </div>
              }
            />
          </div>

          <Table<TableData>
            fields={tableHead}
            isLoading={isLoading || isFetching || isRefetching}
            tableData={rates || []}
            builder={(field, data) => {
              switch (field.name) {
                case "status":
                  return (
                    <span className={`${getStatus(data[field.name])}`}>
                      {data[field.name]}
                    </span>
                  );

                default:
                  return data[field.name];
              }
            }}
          />
        </div>
        <div>
          {rates?.length > 0 && (
            <PaginationComponent
              totalCounts={totalCounts}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              showOnSmallScreen={false}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default LandingSettlement;
