import React, { FC } from "react";

export const DescendPlaneIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.09 14.45L12.4733 0.47998L15.695 1.34331L20.3783 16.4016L29.1466 18.7516C29.4638 18.8366 29.7611 18.9831 30.0216 19.1829C30.2821 19.3828 30.5007 19.6319 30.6649 19.9162C30.8291 20.2005 30.9357 20.5144 30.9786 20.8399C31.0215 21.1654 30.9999 21.4962 30.915 21.8133C30.8301 22.1305 30.6835 22.4278 30.4837 22.6883C30.2839 22.9488 30.0347 23.1674 29.7504 23.3316C29.4661 23.4958 29.1522 23.6024 28.8267 23.6453C28.5012 23.6882 28.1705 23.6666 27.8533 23.5816L2.89997 16.895L4.19331 12.065L4.59664 12.1733L6.11998 17.7566L1.74164 16.585C1.38287 16.4884 1.06664 16.2748 0.843181 15.9779C0.619726 15.6811 0.501861 15.3181 0.508308 14.9466L0.658308 5.94165L3.07331 6.58831L4.59664 12.1733L13.09 14.45ZM2.66664 28.6666H29.3333V32H2.66664V28.6666Z"
        fill={className}
      />
    </svg>
  );
};
