import { AxiosError } from "axios";
import moment from "moment";
import { FC, useEffect } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Button, SideModal } from "../../../../components";

export const RequestDetail: FC<{
  showSideModal: boolean;
  setShowSideModal: any;
  entryData: any;
  handleapprove: any;
  handlereject: any;
  isAppprovng: any;
  isRejecting: any;
}> = ({ showSideModal, setShowSideModal, entryData, handleapprove, handlereject, isAppprovng,isRejecting }) => {
  
  return (
    <SideModal isOpen={showSideModal} setIsOpen={setShowSideModal}>
      <div className="px-5">
        <h3 className="font-semibold mt-4">Additional Information</h3>
        <hr className="bg-gray-300 h-[2px] mb-8 mt-4" />
        <div className="flex flex-col mt-12">
          <div className="flex justify-between gap-4 mt-4">
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">Username</p>
              <p className="text-[16px] font-semibold mt-3">Kunle Greg</p>
            </div>
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">User role</p>
              <p className="text-[16px] font-semibold mt-3">End user</p>
            </div>
          </div>
          <div className="flex justify-between gap-4 mt-6">
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">
                Phone Number
              </p>
              <p className="text-[16px] font-semibold mt-3">090332211444</p>
            </div>
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">Email</p>
              <p className="text-[16px] font-semibold mt-3">
                {entryData?.CreatedBy}
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-4 mt-6">
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">
                Entity Type
              </p>
              <p className="text-[16px] font-semibold mt-3">{entryData?.entity}</p>
            </div>
            <div className="w-48">
              <p className="font-light text-[14px] text-[#848484]">
                Request Date
              </p>
              <p className="text-[16px] font-semibold mt-3">
                {entryData?.createdDate}
              </p>
            </div>
          </div>
          <div className="w-full mt-12">
            <p className="font-light text-[14px] text-[#848484]">Comments</p>
            <p className="text-[16px] font-semibold mt-3">
              Created invoice for revenue PORT CHARGE (EXPORT) and servicer
              provider Others. Invoice Number: 5201040767864, Amount: NGN450.00,
              Name: PLUS GRACE EXPRESS, Email: billing.support@faan.gov.ng,
              Phone: 09087561230, Location: LOS, RRR: 290582624532
            </p>
          </div>
          <div className="flex justify-between mt-12">
            <Button
              label="Approve"
              className={`w-[calc(48%)] bg-[#219653]`}
              variant="dynamic"
              onClick={handleapprove}
              isLoading={isAppprovng}
              isDisabled={entryData?.StatusId !== 0 ? true : false}
              />
            <Button
              label="Reject"
              className="w-[calc(48%)] bg-[#CA1B1B]"
              variant="dynamic"
              onClick={handlereject}
              isLoading={isRejecting}
              isDisabled={entryData?.StatusId !== 0 ? true : false}
            />
          </div>
        </div>
      </div>
    </SideModal>
  );
};
