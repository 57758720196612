import React, { FC } from "react";

export const SettingsIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.88328 0.451453C4.92336 0.0804089 6.0474 0.0122113 7.12471 0.254788C8.20202 0.497365 9.18835 1.04075 9.96902 1.82177C10.7497 2.60279 11.2926 3.58936 11.5347 4.66678C11.7768 5.7442 11.7081 6.86821 11.3366 7.90812L29.4116 25.9831L25.875 29.5181L7.79995 11.4448C6.75987 11.8158 5.63583 11.884 4.55852 11.6415C3.48121 11.3989 2.49489 10.8555 1.71421 10.0745C0.933543 9.29345 0.390598 8.30688 0.148503 7.22946C-0.0935922 6.15204 -0.0248921 5.02803 0.346617 3.98812L4.07328 7.71645C4.3039 7.95523 4.57976 8.14569 4.88477 8.27671C5.18978 8.40773 5.51783 8.4767 5.84978 8.47958C6.18173 8.48246 6.51093 8.41921 6.81817 8.29351C7.12541 8.16781 7.40454 7.98217 7.63927 7.74744C7.874 7.51271 8.05964 7.23358 8.18534 6.92634C8.31104 6.6191 8.3743 6.2899 8.37141 5.95795C8.36853 5.626 8.29956 5.29795 8.16854 4.99294C8.03751 4.68793 7.84706 4.41207 7.60828 4.18145L3.88162 0.449786L3.88328 0.451453ZM21.1616 3.59145L26.465 0.644786L28.8216 3.00145L25.875 8.30479L22.9283 8.89479L19.395 12.4298L17.0366 10.0731L20.5716 6.53812L21.1616 3.59145ZM9.37662 16.5548L12.9116 20.0915L4.66162 28.3415C4.20668 28.7921 3.59615 29.0511 2.95592 29.0648C2.31569 29.0786 1.69459 28.8461 1.22071 28.4154C0.746818 27.9847 0.456278 27.3886 0.409002 26.75C0.361725 26.1113 0.561317 25.4789 0.966617 24.9831L1.12828 24.8048L9.37828 16.5548H9.37662Z"
        fill={className}
      />
    </svg>
  );
};
