import React, { FC, useState } from "react";

export const Tab: FC<{ tabs: any; className?: string }> = ({
  tabs,
  className,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <section
        className={`bg-gray-100 border-b-2 border-b-gray-150 transition-all ease-in-out flex gap-4 w-full ${className}`}
      >
        {tabs.map((tab: any, index: number) => (
          <button
            key={index}
            className={` pb-2 text-[10px] xl:text-xs ${
              activeTab === index
                ? "text-green-600 border-b border-b-green-600"
                : "text-gray-300"
            }`}
            onClick={() => setActiveTab(index)}
          >
            <span className=" text-[11px] xl:text-xs">{tab.title}</span>
            <span
              className={`${activeTab === index ? "border-b-green-600" : ""}`}
            />{" "}
          </button>
        ))}
      </section>
      {tabs[activeTab].render()}
    </>
  );
};
