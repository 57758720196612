import { useState } from "react";
import { useQuery } from "react-query";
import { getEntities } from "../utils/services/entities";

export default function useEntities() {
  const [entities, setEntities] = useState([])

  const { refetch } = useQuery(["fetchCountry"], () => getEntities(), {
    enabled: true,
    retry: 2,
    onSuccess: (response) => {
      setEntities(response.data?.Results);
    },
  });

  const getAllEntities = () => {
    refetch();
  };

  return { entities, getAllEntities };
}
