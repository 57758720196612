import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { Button, Input, Select, SideModal } from "../../../..";
import { ErrorSummary } from "../../../../../utils/errorSummary";
import { getSavedState } from "../../../../../utils/localStorage";
import {  getAirlines } from "../../../../../utils/services/settings/airlineConfiguration";
import { createMakerChecker } from "../../../../../utils/services/settings/makerchecker";
import { schema } from "./schema";

export const NewAircraft: FC<{
  showSideModal: boolean;
  setShowSideModal: any;
}> = ({ showSideModal, setShowSideModal }) => {
  const savedState = getSavedState("LNPUser");
  const [airlines, setAirlines] = useState([]);
  const [filters, setFilters] = useState({
    from: 0,
    pageSize: 100,
    parameter: {
      // startDate: undefined,
      // endDate: undefined,
      // airline: undefined,
      // aircraftType: undefined,
      // registrationNumber: undefined,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    const aircraftData = {
      airlineId: parseInt(data?.flightOperator),
      registrationNumber: data?.regNumber,
      seatCapacity: parseInt(data?.seatCapacity),
      weight: parseInt(data?.aircraftWeight),
      flightType: data?.aircraftType
    }

    const MODE = process.env.NODE_ENV === "development";
    const url = '/Aircraft/CreateAircraft'

    mutateAsync({
      //@ts-ignore
      URL: MODE ? `${process.env.REACT_APP_API_URL}${url}` : `${window.env.passerelleUrl}/api${url}`,
      AppId: MODE
            //@ts-ignore
      ? parseInt(process.env.REACT_APP_MAKER_CHECKER_ID)
      : //@ts-ignore
        window.env.makerCheckerAppId,
      CreatedBy: savedState.userName,
      Payload: JSON.stringify(aircraftData),
      EntityTypeId: 27
    });
  };

  const { isLoading: loading, refetch } = useQuery(
    ["fetchAllAirlines"],
    () => getAirlines(filters),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response) => {
        setAirlines(response.data?.resultList);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error)
    },
    }
  );
  
  const {
    mutateAsync,
    isLoading,
    isError,
    error,
    data,
  } = useMutation(createMakerChecker, {
      onSuccess: () => {
        setShowSideModal(false)
        toast.success("New Aircraft Added Sent to Maker checker for Approval");
        refetch()
      },
      onError: (error: AxiosError) => {
        setShowSideModal(false)
        toast.error('Create Aircraft unsuccessful')
      },
  })

  return (
    <SideModal isOpen={showSideModal} setIsOpen={setShowSideModal}>
      <form className="p-6" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="font-semibold">Add Aircraft</h3>

        <hr className="bg-gray-300 h-[2px] mb-8 mt-4" />

        <div className="mb-3">
          <ErrorSummary errors={errors} />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Select
            label="Airline/Operator"
            containerClass="w-full"
            name="flightOperator"
            options={airlines?.map(
              (airline: { id: any; name: any }) => {
                return {
                    value: airline.id,
                    label: airline.name,
                }
              }
            )}
            control={control}
            error={errors.flightOperator}
          />
          <Input
            label="Aircraft Registration Number"
            register={register("regNumber", {
              required: true,
            })}
            error={errors?.regNumber}
          />
          <Select
            label="Aircraft Type"
            containerClass="w-full"
            name="aircraftType"
            options={[
              // { label: "", value: "" },
              { label: "Passenger", value: "passenger" },
              { label: "Cargo", value: "cargo" },
            ]}
            control={control}
            error={errors.aircraftType}
          />
          <Input
            label="Seat capacity"
            register={register("seatCapacity", {
              required: true,
            })}
            type='number'
            error={errors?.seatCapacity}
          />
          <Input
            label="Aircraft weight"
            register={register("aircraftWeight", {
              required: true,
            })}
            type='number'
            error={errors?.aircraftWeight}
          />
        </div>

        <Button
          label="Add Aircraft"
          className="w-[242px] mt-14"
          variant="filled"
          isLoading={isLoading}
        />
      </form>
    </SideModal>
  );
};
