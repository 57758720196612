import { Tab } from "../../../components";
import LandingStmt from "../../../components/AccountStmt/LandingStmt/LandingStmt";
import ParkingStmt from "../../../components/AccountStmt/ParkingStmt/ParkingStmt";

const JetOperators = () => {
  return (
    <Tab
      className="sticky top-[65px] xl:top-[72px] pl-7 z-10"
      tabs={[
        {
          title: "Landing Account Statement",
          render: () => <LandingStmt />,
        },
        {
          title: "Parking Account Statement",
          render: () => <ParkingStmt />,
        },
      ]}
    />
  );
};

export default JetOperators;
