import { motion } from "framer-motion";
import React from "react";
const Statistics = () => {
  return (
    <motion.div
      className="p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      Statistics
    </motion.div>
  );
};

export default Statistics;
