import { AxiosResponse } from "axios";
import { SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import { Button, PaginationComponent, Table } from "../../../components";
import { MaterialSelect } from "../../../components";
import DownloadIcon from "../../../assets/img/icons/download-icon.svg";
import { fetchFlightData } from "../../../utils/services/flightOperations";
import { motion } from "framer-motion";
import { MaterialInput } from "../../UIKit/Input/MaterialInput";

type TableData = {
  sn?: number;
  airline?: string;
  flightNo: string;
  regNo: string;
  flightDate: string;
  flightType?: string;
  baseAirport?: string;
  arrivalAirport?: string;
  estimatedArrivalTime?: string;
  expectedDepartureTime?: string;
  actualArrival?: string;
  actualDeparture?: string;
};
const tableHead: { name: keyof TableData; displayName: any }[] = [
  { name: "sn", displayName: "S/N" },
  { name: "airline", displayName: "Airline" },
  { name: "flightNo", displayName: "Flight No" },
  { name: "regNo", displayName: "Reg No" },
  { name: "flightDate", displayName: "Flight Date" },
  { name: "flightType", displayName: "Flight Type" },
  { name: "baseAirport", displayName: "Base Airport" },
  { name: "arrivalAirport", displayName: "Arrival Airport" },
  {
    name: "estimatedArrivalTime",
    displayName: "Estimated Arrival Time (ADR16)",
  },
  {
    name: "expectedDepartureTime",
    displayName: "Expected Departure Time (ADR16)",
  },
  { name: "actualArrival", displayName: "Actual Arrival Time(Field)" },
  { name: "actualDeparture", displayName: "Actual Departure Time(Field)" },
];

const FundWallet = () => {
  const [totalCounts, settotalCounts] = useState(20);
  const [pageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [rates, setRates] = useState([]);

  const [age, setAge] = useState("1");

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAge(event.target.value);
  };

  const { isLoading, isRefetching, isFetching } = useQuery(
    ["fetchFlightData"],
    () => fetchFlightData(),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response: AxiosResponse) => {
        setRates(response.data?.resultList);
        settotalCounts(response.data?.totalCount);
      },
    }
  );

  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pl-7 xl:pl-10 py-3 pr-7">
        <div className="flex justify-between mt-4">
          <div className="flex flex-wrap items-end gap-6 w-full">
            <MaterialInput
              id="date"
              label="Start Date"
              type="date"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{ width: 160 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MaterialInput
              id="date"
              label="End Date"
              type="date"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{ width: 160 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MaterialSelect
              value={age}
              onChange={handleChange}
              label={<span className="text-gray-300"> Airport</span>}
              options={[
                { label: "", value: null },
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />

            <MaterialSelect
              value={age}
              onChange={handleChange}
              label={<span className="text-gray-300">Airline</span>}
              options={[
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />

            <Button label="Search" className="w-40" variant="filled" />
          </div>
        </div>

        <div className="bg-gray-100 mt-14">
          <div className="flex items-center justify-between  p-2">
            <div className="flex items-center py-1 px-3 gap-4">
              <h3 className="text-sm text-black-900 font-semibold min-w-fit">
                Air Flight Information
              </h3>
            </div>

            <Button
              variant="outlined"
              className="h-[38px]"
              label={
                <div className="flex gap-3">
                  <img src={DownloadIcon} alt="" />
                  Download Report
                </div>
              }
            />
          </div>

          <Table<TableData>
            fields={tableHead}
            isLoading={isLoading || isFetching || isRefetching}
            tableData={rates || []}
            builder={(field, data) => {
              return data[field.name];
            }}
          />
        </div>
        <div>
          {rates?.length > 0 && (
            <PaginationComponent
              totalCounts={totalCounts}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              showOnSmallScreen={false}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default FundWallet;
