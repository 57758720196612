import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useTitle() {
  const [title, setTitle] = useState("");
  const [hideBottomBorder, sethideBottomBorder] = useState(false);
  const location = useLocation();
  const path = location?.pathname;

  const locationMapping: {
    [key: string]: { title: string; hideBottomBorder?: boolean };
  } = {
    "/admin/scheduled-flight": {
      title: "Scheduled Flight",
    },
    "/admin/billable-flights": {
      title: "Billable Flight",
      hideBottomBorder: true,
    },
    "/admin/parking-operations": { title: "Parking Operations" },
    "/admin/airline-wallets": {
      title: "Airline Wallets",
      hideBottomBorder: true,
    },
    "/admin/account-statement": {
      title: "Statement of Account",
      hideBottomBorder: true,
    },
    "/admin/statistics": { title: "Statistics" },
    "/admin/settlement": { title: "Settlement", hideBottomBorder: true },
    "/admin/invoice-module": { title: "Invoice Module" },
    "/admin/audit-trail": { title: "Audit Trail" },
    "/admin/approvals": { title: "Approvals" },
    "/admin/flight-operations/flight-data": { title: "Flight Data" },
    "/admin/flight-operations/jet-operators": {
      title: "Jet Operator",
      hideBottomBorder: true,
    },
    "/admin/flight-operations/domestic": { title: "Domestic" },
    "/admin/settings/rate": { title: "Rate", hideBottomBorder: true },
    "/admin/settings/exchange-rate": { title: "Exchange Rate" },
    "/admin/settings/airline-config": {
      title: "Airline Configuration",
      hideBottomBorder: true,
    },
    "/admin/settings/user-permissions": {
      title: "Users and Permission",
      hideBottomBorder: true,
    },
    "/admin/settings/notification": { title: "Notification" },
    "/admin/settings/reorder-level": {
      title: "Reorder Level",
      hideBottomBorder: true,
    },
    "/admin/settings/settlement-period": { title: "Set Settlement Period" },
  };
  const fetchUrl = () => {
    setTitle(locationMapping[path]?.title);
    let show = locationMapping[path]?.hideBottomBorder;

    sethideBottomBorder(show || false);
  };

  useEffect(() => {
    fetchUrl();
  }, [path]);

  return { title, hideBottomBorder };
}
