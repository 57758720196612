import { FC } from "react";
import { Outlet } from "react-router-dom";
import useTitle from "../../../hooks/useTitle";
import { setShowDropdown } from "../../../redux/features/ui-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hooks";
import AdminContent from "./Content";
import { AdminHeader } from "./Header/Header";
import AdminSidebar from "./Sidebar";
import MobileSidebar from "./Sidebar/Mobile/Mobile";

const GenericLayout: FC = () => {
  const dispatch = useAppDispatch();
  const { showDropDown, toggleSidebar, leaveOpen } = useAppSelector(
    (state: any) => state.ui
  );
  const { title, hideBottomBorder } = useTitle();
  const handleLayoutClick = () => {
    if (showDropDown && leaveOpen) {
      dispatch(setShowDropdown(null));
    }
    if (toggleSidebar) {
      // setToggleSidebar(false);
    }
  };

  return (
    <main
      className="flex relative h-screen bg-white w-screen overflow-x-hidden"
      onClick={handleLayoutClick}
    >
      <MobileSidebar />
      <AdminSidebar />
      <AdminContent>
        <AdminHeader pageTitle={title} hideBottomBorder={hideBottomBorder} />
        <Outlet />
      </AdminContent>
    </main>
  );
};

export default GenericLayout;
