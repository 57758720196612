import React, { MutableRefObject, ReactElement, useRef } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";

interface IModal<T> {
  isOpen: boolean;
  showClosBtn?: boolean;
  setIsOpen: any;
  modalClass?: string;
  contentClass?: string;
  data?: T;
  title?: string;
  children: React.ReactNode;
  handleClose?: () => void;
}

export function AppModal<Tdata>({
  isOpen,
  setIsOpen,
  title,
  data,
  handleClose,
  modalClass,
  contentClass,
  showClosBtn,
  children,
}: IModal<Tdata>): ReactElement {
  const ref: MutableRefObject<undefined> = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <>
      {isOpen ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto bg-opacity-[0.6] bg-green-900 fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              //@ts-ignore
              ref={ref}
              className={`relative w-full my-6 mx-auto max-w-5xl px-4  ${modalClass}`}
            >
              <div className="overflow-y-auto  border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-lg outline-none text-current ">
                {title ? (
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                    <h3 className="text-3xl font-semibold">{title}</h3>

                    {showClosBtn && (
                      <button
                        className="bg-transparent border-0 text-black float-right"
                        onClick={() => setIsOpen(false)}
                      >
                        <span className="text-black opacity-7 h-6 w-6 text-xl block  py-0 rounded-full">
                          x
                        </span>
                      </button>
                    )}
                  </div>
                ) : (
                  <>
                    {showClosBtn && (
                      <div className="flex items-start justify-end p-5">
                        <button
                          className="bg-transparent border-0 text-black float-right"
                          onClick={() => setIsOpen(false)}
                        >
                          <span className="text-black opacity-7 h-6 w-6 text-xl block  py-0 rounded-full">
                            x
                          </span>
                        </button>
                      </div>
                    )}
                  </>
                )}
                {}
                <div
                  className={`flex justify-center items-center ${contentClass}`}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
