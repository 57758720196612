import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";

type ErrorSummaryProps<T> = {
  errors: FieldErrors<T>;
};
export function ErrorSummary<T>({ errors }: ErrorSummaryProps<T>) {
  if (Object.keys(errors).length === 0) {
    return null;
  }
  return (
    <div className="text-red-700 bg-red-100 text-[11px] border-[0.5px] border-red-700 rounded-md py-1 px-6">
      {Object.keys(errors).map((fieldName) => (
        <ErrorMessage
          errors={errors}
          name={fieldName as any}
          as="div"
          key={fieldName}
        />
      ))}
    </div>
  );
}
