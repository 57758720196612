import React, { FC } from "react";

export const InvoiceIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.5 1.49317V16.5065C15.4983 16.7254 15.4105 16.9347 15.2557 17.0894C15.1008 17.2441 14.8914 17.3316 14.6725 17.3332H1.3275C1.10818 17.3332 0.897826 17.2461 0.742664 17.0911C0.587501 16.9361 0.500221 16.7258 0.5 16.5065V1.49317C0.501746 1.27432 0.589515 1.06494 0.744348 0.910265C0.899181 0.755587 1.10865 0.668029 1.3275 0.666504H14.6725C15.1292 0.666504 15.5 1.0365 15.5 1.49317ZM7.41083 9.93484L5.34833 7.8715L4.17 9.05067L7.41083 12.2915L12.125 7.57734L10.9467 6.399L7.41083 9.934V9.93484Z"
        fill={className}
      />
    </svg>
  );
};
