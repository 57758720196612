import { ReactElement, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "../MenuItem";
import { FlyIcon } from "../../../../../assets/icon/flyIcon";
import { PlaneLevelIcon } from "../../../../../assets/icon/PlaneLevelIcon";
import { BillIcon } from "../../../../../assets/icon/BillIcon";
import { DescendPlaneIcon } from "../../../../../assets/icon/DescendPlaneIcon";
import { AccountIcon } from "../../../../../assets/icon/AccountIcon";
import { WalletIcon } from "../../../../../assets/icon/Walleticon";
import { StatisticsIcon } from "../../../../../assets/icon/StatisticsIcon";
import { SettlementIcon } from "../../../../../assets/icon/SettlementIcon";
import { InvoiceIcon } from "../../../../../assets/icon/InvoiceIcon";
import { SettingsIcon } from "../../../../../assets/icon/SettingsIcon";
import { AuditIcon } from "../../../../../assets/icon/AuditIcon";
import { LogOutIcon } from "../../../../../assets/icon/LogOutIcon";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/store/hooks";
import { setCurrentTab } from "../../../../../redux/features/ui-slice";
import { getUserPermissions } from "../../../../../utils/localStorage";
import { FlightOperationsDD, SettingstDD } from "./model";

function Menu(): ReactElement {
  const permission = getUserPermissions();
  const [, startTransition] = useTransition();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector((state) => state.ui.currentTab);

  const currentTabHandler = (name: string) => {
    if (currentTab === name) {
      startTransition(() => {
        dispatch(setCurrentTab(""));
      });
    } else {
      startTransition(() => {
        dispatch(setCurrentTab(name));
      });
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="h-[calc(100vh_-_62px)] overflow-y-scroll relative scrollbar-hide pt-6 xl:pt-8 pb-8">
      <ul className="pb-12 border-b border-b-green-650 border-collapse bg-green-900">
        {permission.has("scheduled_flight") && (
          <MenuItem
            name="Schedule Flights"
            path="/admin/scheduled-flight"
            Icon={FlyIcon}
            menuAction={() => navigate("/admin/scheduled-flight")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("flight_data") && (
          <MenuItem
            name="Flight Operations"
            path="flight-operation"
            Icon={PlaneLevelIcon}
            menuAction={() => navigate("/admin/flight-operations/flight-data")}
            currentTabHandler={currentTabHandler}
            options={FlightOperationsDD}
            dropDown={true}
          />
        )}
        {permission.has("billable_flights") && (
          <MenuItem
            name="Billable Flights"
            path="/admin/billable-flights"
            Icon={BillIcon}
            menuAction={() => navigate("/admin/billable-flights")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("notification") && (
          <MenuItem
            name="Parking Operations"
            path="/admin/parking-operations"
            Icon={DescendPlaneIcon}
            menuAction={() => navigate("/admin/parking-operations")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("airline_wallets") && (
          <MenuItem
            name="Airline Wallets"
            path="/admin/airline-wallets"
            Icon={WalletIcon}
            menuAction={() => navigate("/admin/airline-wallets")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("account_statement") && (
          <MenuItem
            name="Statement of Account"
            path="/admin/account-statement"
            Icon={AccountIcon}
            menuAction={() => navigate("/admin/account-statement")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("statistics") && (
          <MenuItem
            name="Statistics"
            path="/admin/statistics"
            Icon={StatisticsIcon}
            menuAction={() => navigate("/admin/statistics")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("settlement") && (
          <MenuItem
            name="Settlement"
            path="/admin/settlement"
            Icon={SettlementIcon}
            menuAction={() => navigate("/admin/settlement")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("invoice_module") && (
          <MenuItem
            name="Invoice Module"
            path="/admin/invoice-module"
            Icon={InvoiceIcon}
            menuAction={() => navigate("/admin/invoice-module")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("setting") && (
          <MenuItem
            name="Settings"
            path="settings"
            Icon={SettingsIcon}
            menuAction={() => {
              permission.has("settings_rate") ?
                navigate("/admin/settings/rate") :
                navigate("/admin/settings/airline-config")
            }}
            currentTabHandler={currentTabHandler}
            options={SettingstDD}
            dropDown={true}
          />
        )}
        {permission.has("audit_trail") && (
          <MenuItem
            name="Audit Trail"
            path="/admin/audit-trail"
            Icon={AuditIcon}
            menuAction={() => navigate("/admin/audit-trail")}
            currentTabHandler={currentTabHandler}
          />
        )}
        {permission.has("maker_checker") && (
          <MenuItem
            name="Maker Checker"
            path="/admin/approvals"
            Icon={InvoiceIcon}
            menuAction={() => navigate("/admin/approvals")}
            currentTabHandler={currentTabHandler}
          />
        )}
      </ul>
      <MenuItem
        name="Log Out"
        path="log-out"
        Icon={LogOutIcon}
        menuAction={handleLogout}
        currentTabHandler={currentTabHandler}
      />
    </div>
  );
}

export default Menu;
