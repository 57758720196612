import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getAirportBase } from '../utils/services/settings/airlineConfiguration'

export default function useAirportBase() {
    const [airportBase, setAirportBase] = useState([])
    const [countryCode, setCountryCode] = useState('')

    const { refetch } = useQuery(["GetAllAirportBase"], () => getAirportBase(countryCode), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            console.log({response});
            setAirportBase(response.data.results.filter((item:any) => item?.name !== null))
        },
    })

    const getAllAirportBase = () => {
        refetch();
    }

    
    const getCountryCode = (countryCode: string) => {
        setCountryCode(countryCode)
    }

    useEffect(() => {
        refetch()
    }, [countryCode])

    return { airportBase, getAllAirportBase, getCountryCode }
}
