import { ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../assets/img/navHeader/avatar.png";
import { getSavedState } from "../../utils/localStorage";
const LNPUser = getSavedState("LNPUser");

type IProfileDropdown = {
  isOpen: boolean;
  setIsOpen: any;
};

export function ProfileDropdown({
  isOpen,
  setIsOpen,
}: IProfileDropdown): ReactElement {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    // location.reload()
  };
  return (
    <div
      className={
        " fixed overflow-hidden z-40 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out duration-1000 " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all opacity-0 translate-x-full  ")
      }
    >
      <div className="origin-top-right top-4 right-2 absolute sm:w-72 w-[calc(80%)] z-10 rounded-md shadow-lg bg-white opacity-100 focus:outline-none py-2">
        <div className="p-4">
          <div className="bg-[#F1F1F1] flex gap-2 items-center px-3 py-1 rounded-sm">
            <div className="">
              <img src={avatar} alt="avatar" />
            </div>
            <div className="cursor-default w-96">
              <p className="font-Inter font-semibold text-sm">
                {LNPUser?.fullName}
              </p>
              <p className="font-Inter text-[#003B65] text-sm">
                {LNPUser?.organization}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="border-t border-b flex justify-center items-center py-4">
                    <span className="font-Inter text-base font-semibold text-[#364657] cursor-pointer">
                        View Profile
                    </span>
                </div> */}
        <div className="border-t border-b flex justify-center items-center py-4">
          <Link
            to="/profile/change-password"
            className="font-Inter text-base font-semibold text-[#364657] cursor-pointer"
          >
            Change Password
          </Link>
        </div>
        <div className="flex justify-center items-center py-4">
          <span
            className="font-Inter text-base font-semibold text-[#2F80ED] cursor-pointer"
            onClick={handleLogout}
          >
            Sign out
          </span>
        </div>
      </div>
      <div
        className="w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </div>
  );
}
