import { devInstance } from '../axios/axiosInstance'
const MODE = process.env.NODE_ENV === "development";

export const getRateHistory = async () => {
  const url = '/Rate/GetRates'
  const response = await devInstance.get(url);
  return response;
}; 


// export const fetchCurrentRate = async () => {
//   const response = await axios.get("/Rate/current-rate");
//   return response;
// };

export const addNewRate = async (payload: any) => {
  const url = '/Rate/CreateRate'
  const data = await devInstance.post(url, payload);
  return data.data;
};
