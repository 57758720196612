import * as yup from "yup";

export const schema = yup.object().shape({
  airlineName: yup.string().required("Airline name is required"),
  homeCountry: yup.string().required("Home country is required"),
  category: yup.string().required("Category is required"),
  ebipsAIN: yup.string().required("AIN is required"),
  airlineAirportBase: yup.array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string().required("Airline airport base is required"),
      })
    )
});

export interface IFormInput {
  startDate: string;
}
