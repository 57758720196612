import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Input, PaginationComponent, Select, Table } from "../../..";
import { getRateHistory } from "../../../../utils/services/rate";
import DownloadIcon from "../../../../assets/img/icons/download-icon.svg";
import { TableData, tableHead } from "../models/airline";
import { SideModal } from "../../../UIKit/Modals/SideModal";
import { motion } from "framer-motion";
import { MaterialInput2 } from "../../../UIKit/Input/MaterialInput";
import { MaterialSelect2 } from "../../../UIKit/Select/MaterialSelect";
import { ErrorSummary } from "../../../../utils/errorSummary";
import { useForm } from "react-hook-form";
import { IFormInput } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { NewAirline } from "./NewAirline/NewAirline";
import {AirlineDetail } from "./AirlineDetails/details"
import { downloadAirline, getAirlines } from "../../../../utils/services/settings/airlineConfiguration";
import { toast } from "react-toastify";
import moment from "moment";
import useCountry from "../../../../hooks/useCountries";
import { DetailsIcon } from "../../../../assets/icon/eye";
import { downloadFile } from "../../../../utils/downloadFile";
import { AxiosError } from "axios";

export const Airline = () => {
  const [totalCounts, settotalCounts] = useState(0);
  const [pageSize] = useState(10);
  // const { countries, getAllCountries } = useCountry();
  const [currentPage, setcurrentPage] = useState(1);
  const [airlines, setAirlines] = useState([]);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showDetailsModal, setDetailsSideModal] = useState(false);
  const [modalDetail, setModalDetal] = useState({})

  const [filters, setFilters] = useState({
      from: 0,
      pageSize: 100,
      parameter: {
        startDate: undefined,
        endDate: undefined,
        airline: undefined,
        homeCountry: undefined,
      },
  })

  const downloadPayload = {
    pageSize: 100,
    from: 0,
    parameter: {
      startDate: moment().subtract(30, 'days').format('yy-MM-DD'),
      endDate: moment().format('yy-MM-DD'),
      downloadType: "pdf"
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const { isLoading, isFetching, isRefetching, refetch } = useQuery(
    ["fetchAllAirlines"],
    () => getAirlines(filters),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response) => {
        const data = response.data?.resultList?.map((item: any) => {
          const len = item?.airportBase?.length
          const createdDate = moment(item?.createdDate).format('DD/MM/YYYY')
          const airportBase = item?.airportBase?.reduce((acc: any, item: any, idx: number) => {
            if (len !== idx + 1) {
              acc += `${item?.airportCode}, `
            } else {
              acc += `${item?.airportCode}`
            }
            return acc
          }, '')
          return {
            ...item,
            airportBase,
            createdDate,
            itemDetail: <DetailsIcon 
                          showDetailsModal={showDetailsModal} 
                          setDetailsSideModal={setDetailsSideModal} 
                          onClick={() => {
                              setDetailsSideModal(true)
                              setModalDetal({
                                ...item,
                                airportBase,
                                createdDate,
                              })
                          }} 
                        />
          }
        })
        setAirlines(data);
        settotalCounts(response.data?.totalCount);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error)
    },
    }
  );

  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };

  const searchHandler = (data: any) => {
    setFilters({
      ...filters,
      parameter: {
          ...filters.parameter,
          endDate: data?.endDate,
          airline: data?.airline,
          startDate: data?.startDate,
          homeCountry: data?.homeCountry
      },
    })
  };

  const {
    mutateAsync,
    isLoading: isDownloading,
    isError: isDownloadErr,
    error: downloadError,
    data,
} = useMutation(downloadAirline, {
    onSuccess: ({ data }) => {
        downloadFile(data?.responseType?.downloadUrl, 'airline')
        toast.success(`${data?.responseType?.message}`);
    },
    onError: (error: AxiosError) => {
        toast.error('resource not available')
    },
  })

  const handleDownload = () => {
    toast.success('downloading...')
    mutateAsync(downloadPayload);
  }

  useEffect(() => {
    refetch();
  }, [filters])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pl-7 xl:pl-10 py-3 pr-7">
        <div className="flex justify-between items-center py-3">
          <h2 className="text-gray-300">Airline Configuration</h2>
        </div>

        <hr className="bg-gray-300 h-[1px] mb-8 mt-4" />

        <div className="flex justify-end">
          <Button
            variant="filled"
            label="Add Airline"
            onClick={() => setShowSideModal(true)}
            className="w-40"
          />
        </div>
        <div className="mb-3 mt-10">
          <ErrorSummary errors={errors} />
        </div>
        <form
          onSubmit={handleSubmit(searchHandler)}
          className="flex flex-wrap items-end gap-6 w-full"
        >
          <MaterialInput2
            label="Start Date"
            type="date"
            control={control}
            name="startDate"
            containerClass="w-40"
          />
          <MaterialInput2
            label="End Date"
            type="date"
            control={control}
            name="endDate"
            containerClass="w-40"
          />
          <MaterialSelect2
            containerClass="w-40"
            control={control}
            name="airline"
            label={<span className="text-gray-300">Airline</span>}
            options={airlines?.map(
              (airline: { id: any; name: any }) => {
                return {
                    value: airline.id,
                    label: airline.name,
                }
              }
            )}
          />

          <MaterialSelect2
            containerClass="w-40"
            control={control}
            name="homeCountry"
            label={<span className="text-gray-300">Home country</span>}
            options={[
              { label: "", value: null },
              {
                label: "Nigeria",
                value: "NG",
              },
              { label: "Ghana", value: "GN" },
            ]}
          />

          <Button label="Search" className="w-40" variant="filled" />
        </form>

        <div className="bg-gray-100 mt-14">
          <div className="flex items-center justify-between  p-2">
            <div className="flex items-center py-1 px-3 gap-4">
              <h3 className="text-sm text-black-900 font-semibold min-w-fit">
                Landing Account Record
              </h3>
            </div>

            <Button
              variant="outlined"
              className="h-[38px] w-[200px]"
              label={
                <div className="flex gap-3">
                  <img src={DownloadIcon} alt="download icon" /> Download Report
                </div>
              }
              onClick={handleDownload}
              isLoading={isDownloading}
            />
          </div>

          <Table<TableData>
            fields={tableHead}
            isLoading={isLoading || isFetching || isRefetching}
            tableData={airlines || []}
            builder={(field, data) => {
              return data[field.name];
            }}
          />
        </div>
        <div>
          {airlines?.length > 0 && (
            <PaginationComponent
              totalCounts={totalCounts}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              showOnSmallScreen={false}
            />
          )}
        </div>
      </div>
      <NewAirline
        setShowSideModal={setShowSideModal}
        showSideModal={showSideModal}
        refetch={refetch}
      />
      <AirlineDetail
        setShowSideModal={setDetailsSideModal}
        showSideModal={showDetailsModal}
        entryData={modalDetail}
      />
    </motion.div>
  );
};
