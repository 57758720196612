import { FC } from "react";

export const CurrencySwitch: FC<{
  value: string;
  handleSwitch: Function;
}> = ({ value, handleSwitch }) => {
  return (
    <div className="flex rounded-[10px] bg-white shadow transition-all ease-in-out">
      <div
        className={`p-2 rounded-l-[10px] text-xs font-semibold ${
          value === "NGN" ? "bg-green-600 text-white" : ""
        }`}
        onClick={() => handleSwitch("NGN")}
      >
        NGN
      </div>
      <div
        className={`p-2 rounded-r-[10px] text-xs font-semibold ${
          value === "USD" ? "bg-green-600 text-white" : ""
        }`}
        onClick={() => handleSwitch("USD")}
      >
        USD
      </div>
    </div>
  );
};
