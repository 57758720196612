export type TableData = {
    sn?: number;
    entity?: string;
    createdBy: string;
    createdDate: string;
    approvedBy: string;
    status?: string;
    itemDetail?: any
};

export const tableHead: { name: keyof TableData; displayName: any }[] = [
    { name: "sn", displayName: "S/N" },
    { name: "entity", displayName: "Entity" },
    { name: "createdBy", displayName: "Created By" },
    { name: "createdDate", displayName: "Created Date" },
    { name: "approvedBy", displayName: "Approved By" },
    { name: "status", displayName: "Status" },
    { name: "itemDetail", displayName: "" },
];