import React, { FC } from "react";

export const AuditIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.4999 8.33348C7.27889 8.33348 7.06693 8.42128 6.91065 8.57756C6.75437 8.73384 6.66657 8.9458 6.66657 9.16681V12.5001H8.33324V10.0001H10.8332V12.0835L13.7499 9.16681L10.8332 6.25015V8.33348H7.4999ZM10.5891 1.16098L18.8391 9.41098C18.9953 9.56725 19.0831 9.77918 19.0831 10.0001C19.0831 10.2211 18.9953 10.433 18.8391 10.5893L10.5891 18.8393C10.4328 18.9955 10.2209 19.0833 9.9999 19.0833C9.77893 19.0833 9.56701 18.9955 9.41074 18.8393L1.16074 10.5893C1.00451 10.433 0.916748 10.2211 0.916748 10.0001C0.916748 9.77918 1.00451 9.56725 1.16074 9.41098L9.41074 1.16098C9.56701 1.00476 9.77893 0.916992 9.9999 0.916992C10.2209 0.916992 10.4328 1.00476 10.5891 1.16098Z"
        fill={className}
      />
    </svg>
  );
};
