import React, { FC, ReactNode } from "react";

const AdminContent: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="absolute w-full left-0 bg-white  lg:left-[21%] lg:w-[calc(100%-21%)] min-h-[101vh]">
      {children}
    </main>
  );
};

export default AdminContent;
