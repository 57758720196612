import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Input, PaginationComponent, Select, Table } from "../../..";
import DownloadIcon from "../../../../assets/img/icons/download-icon.svg";
import { TableData, tableHead } from "../models/aircraft";
import { SideModal } from "../../../UIKit/Modals/SideModal";
import { motion } from "framer-motion";
import { MaterialInput2 } from "../../../UIKit/Input/MaterialInput";
import { MaterialSelect2 } from "../../../UIKit/Select/MaterialSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFormInput, schema } from "./schema";
import { ErrorSummary } from "../../../../utils/errorSummary";
import { NewAircraft } from "./NewAircraft/NewAircraft";
import { downloadAircraft, getAircrafts, getAirlines } from "../../../../utils/services/settings/airlineConfiguration";
import moment from "moment";
import { toast } from "react-toastify";
import { downloadFile } from "../../../../utils/downloadFile";
import { AxiosError } from "axios";

export const Aircraft = () => {
  const [totalCounts, settotalCounts] = useState(0);
  const [pageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [aircrafts, setAircrafts] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [registrationNum, setRegistrationNum] = useState([]);
  const [showSideModal, setShowSideModal] = useState(false);
  const [filters, setFilters] = useState({
    from: 0,
    pageSize: 10,
    parameter: {
      startDate: undefined,
      endDate: undefined,
      airline: undefined,
      aircraftType: undefined,
      registrationNumber: undefined,
    },
  });

  const downloadPayload = {
    pageSize: 100,
    from: 0,
    parameter: {
      startDate: moment().subtract(30, 'days').format('yy-MM-DD'),
      endDate: moment().format('yy-MM-DD'),
      downloadType: "pdf"
    }
  }

  const [payload, setPayload] = useState({
    from: 0,
    pageSize: 100,
    parameter: {
      airlineId: "",
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const currentValues = watch();

  const { isLoading: loading, refetch } = useQuery(
    ["fetchAllAirlines"],
    () => getAirlines(filters),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response) => {
        setAirlines(response.data?.resultList);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error)
    },
    }
  );

  const { isLoading, isFetching, isRefetching, refetch: refetchAircrafts } = useQuery(
    ["getAllAircraft"],
    () => getAircrafts(filters),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response) => {
        const data = response.data?.resultList?.map((item: any, index: number) => {
          const createdDate = moment(item?.createdDate).format('DD/MM/YYYY')
          const weight = `${parseFloat(item?.weight)}kg`
          const seatCapacity = item?.seatCapacity.toLocaleString()
          return {
            ...item,
            createdDate,
            weight,
            seatCapacity,
            sn: index + 1,
            edit: <span className="text-[#219653] cursor-pointer">edit</span>
          }
        });
        setAircrafts(data);
        settotalCounts(response.data?.totalCount);
      },
    }
  );

  const { isLoading: isLoadingAircraft, refetch: refetchAircraft } = useQuery(
    ["registrationNumbers"],
    () => getAircrafts(payload),
    {
      enabled: false,
      retry: 0,
      onSuccess: (response) => {
        setRegistrationNum(response?.data?.resultList)
      },
      onError: (error) => {
        console.log(error)
      }
    }
  );

  const {
    mutateAsync,
    isLoading: isDownloading,
    isError: isDownloadErr,
    error: downloadError,
    data,
} = useMutation(downloadAircraft, {
    onSuccess: ({ data }) => {
        downloadFile(data?.responseType?.downloadUrl, 'aircraft')
        toast.success(`${data?.responseType?.message}`);
    },
    onError: (error: AxiosError) => {
        // toast.error(error?.response?.data?.errors[0])
    },
  })
  
  useEffect(() => {
    if (currentValues?.airline) {
      setPayload({
        ...payload,
        parameter: {
          ...payload.parameter,
          airlineId: currentValues?.airline
        }
      })
    }
  }, [currentValues?.airline]);
  
  useEffect(() => {
    if (payload?.parameter?.airlineId) {
      refetchAircraft();
    }
  }, [payload?.parameter?.airlineId]);

  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };

  const searchHandler = (data: any) => {
    setFilters({
      ...filters,
      parameter: {
        ...filters.parameter,
        startDate: data?.startDate,
        endDate: data?.startDate,
        airline: data?.airline,
        aircraftType: data?.aircraftType,
        registrationNumber: data?.registrationNumber,
      },
    })
  };

  const handleDownload = () => {
    toast.success('downloading...')
    mutateAsync(downloadPayload);
  }

  useEffect(() => {
    refetchAircrafts();
  }, [filters])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pl-7 xl:pl-10 py-3 pr-7">
        <div className="flex justify-between items-center py-3">
          <h2 className="text-gray-300">Aircraft Configuration</h2>
        </div>
        <hr className="bg-gray-300 h-[1px] mb-8 mt-4" />
        <div className="flex justify-end">
          <Button
            variant="filled"
            label="Add Aircraft"
            onClick={() => setShowSideModal(true)}
            className="w-40"
          />
        </div>
        <div className="mb-3">
          <ErrorSummary errors={errors} />
        </div>

        <form
          onSubmit={handleSubmit(searchHandler)}
          className="flex flex-wrap items-end gap-6 w-full"
        >
          <MaterialSelect2
            containerClass="w-40"
            control={control}
            name="airline"
            label={<span className="text-gray-300">Airline</span>}
            options={airlines?.map(
              (airline: { id: any; name: any }) => {
                return {
                    value: airline.id,
                    label: airline.name,
                }
              }
            )}
          />

          <MaterialSelect2
            containerClass="w-40"
            control={control}
            name="aircraftType"
            label={<span className="text-gray-300">Aircraft Type</span>}
            options={[
              { label: "", value: null },
              {
                label: "Passanger",
                value: "passenger",
              },
              { label: "Cargo", value: "cargo" },
            ]}
          />

          <MaterialSelect2
            containerClass="w-40"
            control={control}
            name="registrationNumber"
            label={<span className="text-gray-300">Registration No.</span>}
            options={registrationNum?.map(
              (airline: { registrationNumber: any; name: any }) => {
                return {
                    value: airline.registrationNumber,
                    label: airline.registrationNumber,
                }
              }
            )}
          />

          <MaterialInput2
            label="Created Date"
            type="date"
            control={control}
            name="startDate"
            containerClass="w-40"
          />

          <Button label="Search" className="w-40" variant="filled" />
        </form>

        <div className="bg-gray-100 mt-14">
          <div className="flex items-center justify-between  p-2">
            <div className="flex items-center py-1 px-3 gap-4">
              <h3 className="text-sm text-black-900 font-semibold min-w-fit">
                Landing Account Record
              </h3>
            </div>

            <Button
              variant="outlined"
              className="h-[32px]  w-[200px]"
              label={
                <div className="flex gap-3">
                  <img src={DownloadIcon} alt="download icon" /> Download Report
                </div>
              }
              onClick={handleDownload}
              isLoading={isDownloading}
            />
          </div>

          <Table<TableData>
            fields={tableHead}
            isLoading={isLoading || isFetching || isRefetching}
            tableData={aircrafts || []}
            builder={(field, data) => {
              return data[field.name];
            }}
          />
        </div>
        <div>
          {aircrafts?.length > 0 && (
            <PaginationComponent
              totalCounts={totalCounts}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              showOnSmallScreen={false}
            />
          )}
        </div>
      </div>

      <NewAircraft
        showSideModal={showSideModal}
        setShowSideModal={setShowSideModal}
      />
    </motion.div>
  );
};
