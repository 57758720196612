import { getUserPermissions } from "../../../../../utils/localStorage";

const permission = getUserPermissions();

export const FlightOperationsDD = [
  {
    name: "Flight Data",
    url: "/admin/flight-operations/flight-data",
    canAccess: permission.has("flight_data"),
  },
  {
    name: "Jet Operations",
    url: "/admin/flight-operations/jet-operators",
    canAccess: permission.has("jet_operators"),
  },
  {
    name: "Domestic",
    url: "/admin/flight-operations/domestic",
    canAccess: permission.has("domestic_flights"),
  },
];
const isAdmin = [
  {
    name: "Rate",
    url: "/admin/settings/rate",
    canAccess: permission.has("settings_rate"),
  },
  {
    name: "Exchange Rate",
    url: "/admin/settings/exchange-rate",
    canAccess: permission.has("exchange_rate"),
  },
  {
    name: "Users and Persmissions",
    url: "/admin/settings/user-permissions",
    canAccess: permission.has("user_permissions"),
  },
  {
    name: "Notification",
    url: "/admin/settings/notification",
    canAccess: permission.has("notification"),
  },
  {
    name: "Reorder level",
    url: "/admin/settings/reorder-level",
    canAccess: permission.has("reorder-level"),
  },
  {
    name: "Set Settlement Period",
    url: "/admin/settings/settlement-period",
    canAccess: permission.has("settlement_period"),
  },
]
const isUser = [
  {
    name: "Airline Configuration",
    url: "/admin/settings/airline-config",
    canAccess: permission.has("airline_config"),
  },
]
export const SettingstDD = permission.has("settings_rate") ? isAdmin : isUser;
