import { AxiosRequestConfig } from "axios";
import { getSavedState } from "../../localStorage";

export const requestInteceptor = (config: AxiosRequestConfig) => {
  const savedState = getSavedState("LNPUser");
  const lnpToken = savedState?.access_token;

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${lnpToken}`,
  };
  return config;
};
