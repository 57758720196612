import { lazy } from "react";
import { IRoute } from "./model";

const Rate = lazy(() => import("../pages/Admin/Settings/Rate/Rate"));
const AirlineConfig = lazy(
  () => import("../pages/Admin/Settings/AirlineConfig/AirlineConfig")
);
const UserPermissions = lazy(
  () => import("../pages/Admin/Settings/UserPermissions/UserPermissions")
);
const Notification = lazy(
  () => import("../pages/Admin/Settings/Notification/Notification")
);
const ReorderLevel = lazy(
  () => import("../pages/Admin/Settings/ReorderLevel/ReorderLevel")
);
const SettlementPeriod = lazy(
  () => import("../pages/Admin/Settings/SettlementPeriod/SettlementPeriod")
);
const ExchangeRate = lazy(
  () => import("../pages/Admin/Settings/ExchangeRate/ExchangeRate")
);

export const settingsRoutes: IRoute[] = [
  {
    path: "settings/rate",
    comp: <Rate />,
    permission: "settings_rate",
  },
  {
    path: "settings/exchange-rate",
    comp: <ExchangeRate />,
    permission: "exchange_rate",
  },
  {
    path: "settings/airline-config",
    comp: <AirlineConfig />,
    permission: "airline_config",
  },
  {
    path: "settings/user-permissions",
    comp: <UserPermissions />,
    permission: "user_permissions",
  },
  {
    path: "settings/notification",
    comp: <Notification />,
    permission: "notification",
  },
  {
    path: "settings/reorder-level",
    comp: <ReorderLevel />,
    permission: "reorder_level",
  },
  {
    path: "settings/settlement-period",
    comp: <SettlementPeriod />,
    permission: "settlement_period",
  },
];
