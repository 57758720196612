import { FC } from "react";
import ImgNotFound from "../../assets/img/not-found.svg";
import { LandingLayout } from "../../components";

export const NotFound: FC<{ isPublicRoute: boolean }> = ({ isPublicRoute }) => {
  if (isPublicRoute) {
    return (
      <LandingLayout>
        <div className="mb-3">
          The content your are looking for does not exist
        </div>
        <img src={ImgNotFound} alt="not fouund" />
      </LandingLayout>
    );
  }

  // <GenericLayout>
  return (
    <div className=" flex h-[100vh] justify-center items-center ">
      <div className="w-2/5">
        <div className="mb-5 text-center">
          The content your are looking for does not exist
        </div>
        <div className="w-[90%">
          <img className="w-full" src={ImgNotFound} alt="not fouund" />
        </div>
      </div>
    </div>
    // </GenericLayout>
  );
};
