import { AxiosResponse } from "axios";
import { SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Input,
  PaginationComponent,
  Select,
  Table,
} from "../../../components";
import { MaterialSelect } from "../../../components";
import DownloadIcon from "../../../assets/img/icons/download-icon.svg";
import { AppModal } from "../../../components/UIKit/Modals/CenterModal";
import { fetchAuditTrail } from "../../../utils/services/audit";
import { motion } from "framer-motion";
import { MaterialInput } from "../../../components/UIKit/Input/MaterialInput";
type TableData = {
  sn?: number;
  name?: string;
  email: string;
  role: string;
  action: string;
  date?: string;
  time?: string;
};
const tableHead: { name: keyof TableData; displayName: any }[] = [
  { name: "sn", displayName: "S/N" },
  { name: "name", displayName: "Name" },
  { name: "email", displayName: "Email" },
  { name: "role", displayName: "Role" },
  { name: "action", displayName: "Action" },
  { name: "date", displayName: "Date" },
  { name: "time", displayName: "Time" },
];

const AuditTrail = () => {
  const [totalCounts, settotalCounts] = useState(20);
  const [pageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [rates, setRates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [age, setAge] = useState("1");

  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAge(event.target.value);
  };

  const { isLoading, isRefetching, isFetching } = useQuery(
    ["fetchAuditTrail"],
    () => fetchAuditTrail(),
    {
      enabled: true,
      retry: 2,
      onSuccess: (response: AxiosResponse) => {
        setRates(response.data?.resultList);
        settotalCounts(response.data?.totalCount);
      },
    }
  );

  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="pl-7 xl:pl-10 py-3 pr-7">
        <div className="flex justify-between">
          <div className="flex flex-wrap items-end gap-6 w-full">
            <MaterialSelect
              value={age}
              onChange={handleChange}
              label="Report Type"
              options={[
                { label: "", value: null },
                { label: "Ten", value: 10 },
                { label: "Twenty", value: 20 },
              ]}
            />

            <MaterialInput
              id="date"
              label="Start Date"
              type="date"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{ width: 160 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MaterialInput
              id="date"
              label="End Date"
              type="date"
              variant="standard"
              defaultValue="2017-05-24"
              sx={{ width: 160 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button label="Search" className="w-40" variant="filled" />
          </div>
        </div>

        <div className="bg-gray-100 mt-14">
          <div className="flex items-center justify-between  p-2">
            <div className="flex items-center py-1 px-3 gap-4">
              <h3 className="text-sm text-black-900 font-semibold min-w-fit">
                Audit Log
              </h3>
            </div>

            <Button
              variant="outlined"
              className="h-[38px]"
              label={
                <div className="flex gap-3">
                  <img src={DownloadIcon} alt="" />
                  Download Report
                </div>
              }
            />
          </div>

          <Table<TableData>
            fields={tableHead}
            isLoading={isLoading || isFetching || isRefetching}
            tableData={rates || []}
            builder={(field, data) => {
              return data[field.name];
            }}
          />
        </div>
        <div>
          {rates?.length > 0 && (
            <PaginationComponent
              totalCounts={totalCounts}
              pageSize={pageSize}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              showOnSmallScreen={false}
            />
          )}
        </div>
      </div>

      <AppModal
        modalClass="w-[468px]"
        showClosBtn={false}
        isOpen={showModal}
        setIsOpen={setShowModal}
        contentClass="p-10"
      >
        <div className="w-full flex flex-col items-center">
          <h3 className=" text-base font-semibold text-center mb-8 text-green-600">
            Add Landing Reorder Level
          </h3>

          <div className="flex flex-col flex-wrap gap-4 mb-6">
            <Select name="airline" label="Select Airline" options={[]} />
            <Select name="airportBase" label="Airport Base" options={[]} />
            <Select name="currency" label="Currency" options={[]} />
            <Input
              label="Amount"
              className="w-[242px]"
              placeholder="Input Value"
            />
          </div>

          <Button className="w-[243px]" variant="filled" label="Save" />
        </div>
      </AppModal>
    </motion.div>
  );
};

export default AuditTrail;
