import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Input, PaginationComponent, Table } from "../../..";
import {
  addNewRate,
  getRateHistory,
} from "../../../../utils/services/rate";
import DownloadIcon from "../../../../assets/img/icons/download-icon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { rateSchema } from "../schema";
import { ErrorSummary } from "../../../../utils/errorSummary";
import { Rate, tableHead } from "../model";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { motion } from "framer-motion";
import moment from "moment";
import { getSavedState } from "../../../../utils/localStorage";
import { createMakerChecker } from "../../../../utils/services/settings/makerchecker";

export const DollarRate = () => {
  const [totalCounts, settotalCounts] = useState(0);
  const [pageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [rates, setRates] = useState<Rate[]>([]);
  const [dollarRate, setDollarRate] = useState<Rate[]>([]);
  const savedState = getSavedState("LNPUser");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rateSchema),
  });

  const {
    isLoading,
    isFetching,
    isRefetching,
    refetch: regetRateHistory,
  } = useQuery(["getRateHistory"], () => getRateHistory(), {
    enabled: true,
    retry: 2,
    onSuccess: (response) => {
      const data = response.data?.resultList?.map((item: any, index: number) => {
        const createdDate = moment(item?.createdDate).format('DD/MM/YYYY')
        const id = index+1
        return {
          ...item,
          createdDate,
          id
        }
      })
      setRates(data);
      settotalCounts(response.data?.totalCount);
    },
  });

useEffect(() => {
  if (rates?.length > 0) {
    const dollarRateData = rates
      .filter((rate: Rate) => rate.currencyCode.toLowerCase() === "usd")
      .map((item: Rate, index: number) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      setDollarRate(dollarRateData);
  }
}, [rates]);

  const { mutateAsync, isLoading: creatingRate } = useMutation(createMakerChecker, {
    onSuccess: () => {
      toast.success("New Rate Added Sent to Maker checker for Approval");
      reset()
      regetRateHistory();
    },
    onError: (err: AxiosError) => {
      if (err?.response?.data) {
        toast.error("An error occured");
        return;
      }
      toast.error("an error occurred");
    },
  });
  const handlePageChange = (page: { selected: number }) => {
    setcurrentPage(page.selected);
  };

  const addRateHandler = (data: any) => {
    let payload ={
      currency:  4,
      landingDayRate: parseInt(data.landingDayRate),
      landingNightRate: parseInt(data.landingNightRate),
      parkingRate: parseInt(data.parkingRate),
    }

    const MODE = process.env.NODE_ENV === "development";
    const url = '/Rate/CreateRate'
    
    mutateAsync({
      //@ts-ignore
      URL: MODE ? `${process.env.REACT_APP_API_URL}${url}` : `${window.env.passerelleUrl}/api${url}`,
      AppId: MODE
            //@ts-ignore
      ? parseInt(process.env.REACT_APP_MAKER_CHECKER_ID)
      : //@ts-ignore
        window.env.makerCheckerAppId,
      CreatedBy: savedState.userName,
      Payload: JSON.stringify(payload),
      EntityTypeId: 24
  })
  };

  return (
    <motion.div
      className="pl-7 xl:pl-10 py-3 pr-7"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex justify-between items-center py-3">
        <h2 className="text-gray-300">Dollar Rate</h2>
      </div>

      <hr className="bg-gray-300 h-[1px] mb-8 mt-4" />

      <div className="mb-2">
        <ErrorSummary errors={errors} />
      </div>

      <form
        onSubmit={handleSubmit(addRateHandler)}
        className="flex gap-6 items-end"
      >
        <Input
          label={
            <div className="flex text-xs">
              <span className="text-gray-300">Day Landing Rate</span>
              <span className="font-semibold">/Kg</span>
            </div>
          }
          register={register("landingDayRate", {
            required: true,
          })}
        />
        <Input
          label={
            <div className="flex text-[11px]">
              <span className="text-gray-300">Night Landing Rate</span>
              <span className="font-semibold">/Kg</span>
            </div>
          }
          register={register("landingNightRate", {
            required: true,
          })}
        />
        <Input
          label={
            <div className="flex text-xs">
              <span className="text-gray-300">Parking Rate</span>
              <span className="font-semibold">/hr</span>
            </div>
          }
          register={register("parkingRate", {
            required: true,
          })}
        />
        <Button
          label="Add Rate"
          className="h-[40px] w-full"
          variant="filled"
          isLoading={creatingRate}
        />
      </form>

      <div className="bg-gray-100 mt-14">
        <div className="flex items-center justify-between  p-2">
          <div className="flex items-center py-1 px-3 gap-4">
            <h3 className="text-sm text-black-900 font-semibold min-w-fit">
              Rate History
            </h3>
          </div>

          {/* <Button
            variant="outlined"
            className="h-[38px] w-[200px]"
            label={
              <div className="flex gap-3">
                <img src={DownloadIcon} alt="download icon" /> Download Report
              </div>
            }
          /> */}
        </div>

        <Table<Rate>
          fields={tableHead}
          isLoading={isLoading || isFetching || isRefetching}
          tableData={dollarRate || []}
          builder={(field, data) => {
            return data[field.name];
          }}
        />
      </div>
      {/* <div>
        {rates?.length > 0 && (
          <PaginationComponent
            totalCounts={totalCounts}
            pageSize={pageSize}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            showOnSmallScreen={false}
          />
        )}
      </div> */}
    </motion.div>
  );
};
