import React, { FC } from "react";

export const LogOutIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.16671 0.666504H12.8334C13.0544 0.666504 13.2663 0.754301 13.4226 0.910582C13.5789 1.06686 13.6667 1.27882 13.6667 1.49984V16.4998C13.6667 16.7208 13.5789 16.9328 13.4226 17.0891C13.2663 17.2454 13.0544 17.3332 12.8334 17.3332H1.16671C0.945694 17.3332 0.733732 17.2454 0.577452 17.0891C0.421172 16.9328 0.333374 16.7208 0.333374 16.4998V1.49984C0.333374 1.27882 0.421172 1.06686 0.577452 0.910582C0.733732 0.754301 0.945694 0.666504 1.16671 0.666504ZM4.50004 8.1665V5.6665L0.333374 8.99984L4.50004 12.3332V9.83317H9.50004V8.1665H4.50004Z"
        fill={className}
      />
    </svg>
  );
};
