import React from "react";

export const CaretDown = () => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49999 4.33021L1.99374 0.823958L0.992161 1.82554L5.49999 6.33337L10.0078 1.82554L9.00624 0.823958L5.49999 4.33021Z"
        fill="white"
      />
    </svg>
  );
};
