import * as yup from "yup";

export const schema = yup.object().shape({
  startDate: yup.string().required("Start date is required"),
  endDate: yup.string().required("End date is required"),
  airline: yup.string().required("Airline is required"),
  homeCountry: yup.string().required("Homecountry is required"),
});

export interface IFormInput {
  startDate: string;
  endDate: string;
  airline: string;
  homeCountry: string;
}
