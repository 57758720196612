export type TableData = {
    sn?: number;
    createdDate?: string;
    airlineName: string;
    registrationNumber: string;
    flightType?: string;
    seatCapacity: number;
    weight?: string;
    edit: string;
  };
  
  export const tableHead: { name: keyof TableData; displayName: any }[] = [
    { name: "sn", displayName: "S/N" },
    { name: "createdDate", displayName: "Created Date" },
    { name: "airlineName", displayName: "Organization" },
    { name: "registrationNumber", displayName: "Aircraft Registration No" },
    { name: "flightType", displayName: "Aircraft Type" },
    { name: "seatCapacity", displayName: "Seat Capacity" },
    { name: "weight", displayName: "Aircraft Weight" },
    { name: "edit", displayName: "" },
  ];
  