import React, { FC } from "react";

export const WalletIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.333252 10H31.9999C32.4419 10 32.8659 10.1756 33.1784 10.4882C33.491 10.8007 33.6666 11.2246 33.6666 11.6667V28.3333C33.6666 28.7754 33.491 29.1993 33.1784 29.5118C32.8659 29.8244 32.4419 30 31.9999 30H1.99992C1.55789 30 1.13397 29.8244 0.821407 29.5118C0.508847 29.1993 0.333252 28.7754 0.333252 28.3333V10ZM1.99992 0H26.9999V6.66667H0.333252V1.66667C0.333252 1.22464 0.508847 0.800716 0.821407 0.488155C1.13397 0.175595 1.55789 0 1.99992 0ZM21.9999 18.3333V21.6667H26.9999V18.3333H21.9999Z"
        fill={className}
      />
    </svg>
  );
};
