import React from "react";

export const CaretRight = () => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33016 5.50002L0.823913 9.00627L1.8255 10.0079L6.33333 5.50002L1.8255 0.992188L0.823913 1.99377L4.33016 5.50002Z"
        fill="#328C56"
      />
    </svg>
  );
};
