import { FC, ReactNode } from "react";
import FaanLogo from "../../../assets/img/faan-logo.png";

const LandingLayout: FC<{
  children: ReactNode;
  showLogo?: boolean;
  contentContainerClass?: string;
}> = ({ children, showLogo = true, contentContainerClass }) => {
  return (
    <div
      className={` transition-all ease-in-out bg-landingBg bg-no-repeat bg-cover min-h-screen flex flex-col items-center  min-w-full relative ${
        showLogo ? "" : "justify-center"
      }`}
    >
      {showLogo && (
        <div className="flex flex-col items-center justify-center mb-6 xl:mb-12 mt-7 xl:mt-10 sticky top-6 xl:top-14">
          <img src={FaanLogo} alt="faan logo" className="w-20 xl:w-[107px]" />
          <h4 className="text-black-900 font-semibold text-lg xl:text-xl mt-4 xl:mt-5">
            FAAN AIRLINES SERVICES
          </h4>
        </div>
      )}

      <div
        className={`bg-white rounded-[20px] py-4 xl:py-[30px] px-8 xl:px-[50px] min-h-[400px] xl:mt-10 w-11/12 z-10 ${contentContainerClass}`}
      >
        {children}
      </div>
    </div>
  );
};

export default LandingLayout;
