import { FC, ReactNode } from "react";
import { Controller } from "react-hook-form";
import Select, { components, StylesConfig } from "react-select";

const customStyles: StylesConfig = {
  control: (base: Record<string, unknown>, state: any) => ({
    ...base,
    "*": {
      boxShadow: "none !important",
    },
    fontSize: "12px",
    // height: inputHeight,
    borderRadius: "10px",
    width: "auto",
    border: state.isFocused ? "1px solid #27BE63" : "1px solid #848484",
    boxShadow: "none",
  }),
  input: (base: any, state: any) => ({
    ...base,
    fontSize: "12px",
  }),

  singleValue: (base: any) => ({
    ...base,
    color: "#3A434B",
    opacity: "1",
  }),
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: "#3A434B",
      fontSize: "12px",
    };
  },
};

type SingleValue = {
  value: string | number;
  label: string;
};

type OptionModel = SingleValue[];

const CustomSelect: FC<{
  options: OptionModel;
  onChange?: ((newValue: any) => void) | undefined ;
  defaultValue?: any;
  components?: any;
  isDisabled?: boolean;
  placeholder?: string;
  containerClass?: any;
  className?: string;
  label?: ReactNode;
  name: string;
  inputHeight?: any;
  control?: any;
  error?: any;
  isMulti?: boolean;
}> = ({
  options,
  isDisabled,
  placeholder,
  label,
  containerClass,
  className,
  name,
  inputHeight,
  control,
  error,
  isMulti=false,
}) => {
  return (
    <div className={` flex flex-col justify-start  ${containerClass}`}>
      {typeof label === "string" ? (
        <label
          className={` text-black-900  font-semibold text-xs xl:text-[13px] ml-2`}
          htmlFor={name}
        >
          {label}
        </label>
      ) : (
        label
      )}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value, ref } }) => (
          <Select
            placeholder={placeholder}
            classNamePrefix="react-select"
            className={`react-select-container focus:border-green-600 ${className}`}
            options={options}
            onChange={(newValue: any) => {
               onChange(!isMulti ? newValue?.value : newValue)
              }}
            isDisabled={isDisabled}
            value={options.find((c) => c.value === value)}
            defaultValue={options.find((c) =>  c.value === value)}
            styles={customStyles}
            components={{
              ...components,
              IndicatorSeparator: () => null,
            }}
            isMulti={isMulti}
          />
        )}
      />
      {error && (
        <div className="text-[11px] text-red-900 mt-1">{error?.message}</div>
      )}
    </div>
  );
};

export default CustomSelect;
