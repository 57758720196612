import { lazy } from "react";
import { IRoute } from "./model";

const FlightData = lazy(
  () => import("../pages/Admin/FlightOperations/FlightData/FlightData")
);
const JetOperators = lazy(
  () => import("../pages/Admin/FlightOperations/JetOperators/JetOperators")
);
const Domestic = lazy(
  () => import("../pages/Admin/FlightOperations/Domestic/Domestic")
);

export const flighOperationRoutes: IRoute[] = [
  {
    path: "flight-operations/flight-data",
    comp: <FlightData />,
    permission: "flight_data",
  },
  {
    path: "flight-operations/jet-operators",
    comp: <JetOperators />,
    permission: "jet_operators",
  },
  {
    path: "flight-operations/domestic",
    comp: <Domestic />,
    // permission: "domestic_flights",
    permission: "jet_operators",
  },
];
