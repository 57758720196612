export const ForwardCaret = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.83203 18L15.8275 12.0711C15.8671 12.032 15.8671 11.968 15.8275 11.9289L9.83203 6"
                stroke="#27BE63"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}
