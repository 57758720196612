import { FC, ReactNode } from "react";

export const ServiceTypeCard: FC<{
  children: ReactNode;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ children, title, onClick }) => {
  return (
    <div
      className="bg-gray-100 w-[180px] h-[180px] min-w-[180px] xl:min-w-[220px]  xl:h-[220px] rounded-[20px] flex flex-col gap-4 flex-wrap items-center justify-center cursor-pointer hover:shadow-sm hover:scale-105"
      onClick={onClick}
    >
      {children}
      <h6 className="text-center w-[80%] text-black-900 font-semibold text-sm xl:text-base">
        {title}
      </h6>
    </div>
  );
};
