import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { Controller } from "react-hook-form";

export const MaterialInput = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "red",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused": {
      outline: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      outline: 0,
    },
  },
});

export const MaterialInput2: FC<{
  control: any;
  name: string;
  type: string;
  label: string;
  className?: string;
  containerClass?: string;
}> = ({ control, name, type, label, className, containerClass }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          className={containerClass}
          type={type}
          variant="standard"
          label={<span className="text-gray-300">{label}</span>}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            className: `border-transparent focus:border-transparent focus:ring-0 ${className}`,
          }}
          sx={{
            border: "none",
            outline: "none",
            WebkitBoxShadow: "0 0 0 1000px white inset",
          }}
        />
      )}
    />
  );
};
