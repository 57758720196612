import { useState, FC } from "react";
import avatar from "../../../../assets/img/navHeader/avatar.png";
import hamburger from "../../../../assets/img/hamburger.svg";
import arrowDown from "../../../../assets/img/arrowDown.svg";
import { ProfileDropdown } from "../../../ProfileDropdown";
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hooks";
import { setShowSidebar } from "../../../../redux/features/ui-slice";
import { getSavedState } from "../../../../utils/localStorage";

export const AdminHeader: FC<{
  pageTitle: any;
  hideBottomBorder?: boolean;
}> = ({ pageTitle, hideBottomBorder = false }) => {
  const dispatch = useAppDispatch();
  const toggleSidebar = useAppSelector((state) => state.ui.toggleSidebar);
  const [showModal, setShowModal] = useState(false);
  const LNPUser = getSavedState('LNPUser')

  const handleSidebar = () => {
    dispatch(setShowSidebar(!toggleSidebar));
  };

  return (
    <header
      className={`h-[65px] xl:h-[72px] bg-gray-100 ${
        hideBottomBorder ? "border-b-0" : "border-b-2"
      } border-b-gray-150 sticky top-0 flex justify-between z-20`}
    >
      <div className="px-3 lg:hidden">
        <div
          onClick={handleSidebar}
          className="w-[30px] h-[30px] block lg:hidden lg:opacity-0"
        >
          <div className="w-[44px] h-[30px] mt-4">
            <img src={hamburger} alt="hamburger" />
          </div>
        </div>
      </div>

      <div className="flex items-center w-full justify-center">
        <h2 className="text-[15px] xl:text-[18px] font-semibold min-w-fit pl-7 xl:pl-10 tracking-[0.2px]">
          {pageTitle}
        </h2>
        <div className="w-full flex items-center justify-end py-4 ">
          <div className="flex gap-10 items-center pr-4 relative rounded-[10px] bg-white p-2 mr-4">
            <div>
              <span className="hidden md:block Poppins text-sm xl:text-sm capitalize font-semibold">
                {LNPUser?.fullName}
              </span>
              <span className="hidden md:block Poppins text-gray-300 text-[8px]">
               {LNPUser?.userRole || 'Test User'}
              </span>
            </div>

            <div
              className="flex gap-1 cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img
                src={avatar}
                className="w-[25px] h-[25px] border-none"
                alt="dropdown"
              />
              <img src={arrowDown} alt="arrow down" />
            </div>
            <ProfileDropdown isOpen={showModal} setIsOpen={setShowModal} />
          </div>
        </div>
      </div>
    </header>
  );
};
