import axios, { AxiosRequestConfig } from "axios";
import { requestInteceptor } from "./interceptors/requestInterceptor";
import {
  responseErr,
  responseInterceptor,
} from "./interceptors/responseInterceptor";

export const devInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_URL}`
      : //@ts-ignore
        `${window.env.passerelleUrl}/api`,
  headers: {},
});

export const avdInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_AVD_URL}`
      : //@ts-ignore
        `${window.env.passerelleUrl}`,
  headers: {},
});

export const makerCheckerInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_MAKER_CHECKER_URL}`
      : //@ts-ignore
        `${window.env.makerCheckerUrl}/api`,
  headers: {},
});

devInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => requestInteceptor(config),
  (error: any) => {
    return Promise.reject(error);
  }
);
devInstance.interceptors.response.use(
  (res) => responseInterceptor(res),
  async (err) => responseErr(err, devInstance, `$`)
);

makerCheckerInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => requestInteceptor(config),
  (error: any) => {
    return Promise.reject(error);
  }
);
makerCheckerInstance.interceptors.response.use(
  (res) => responseInterceptor(res),
  async (err) => responseErr(err, makerCheckerInstance, "")
);
