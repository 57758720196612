import { ReactElement } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "../UIKit/Button/Button";
import AccessDenied from "../../assets/img/access-denied.svg";
type RouteProps = {
  comp: ReactElement;
  isAllowed?: boolean;
};
export const Access = ({ isAllowed, comp }: RouteProps) => {
  const navigate = useNavigate();
  console.log("allowed?", isAllowed);

  if (isAllowed) {
    return comp ? comp : <Outlet />;
  }
  return (
    <div className="w-full h-screen grid grid-cols-1 lg:grid-cols-2 bg-[#E8ECF5]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-white text-9xl">403</h1>
        <h6 className=" font-bold my-2">Access Denied</h6>
        <p className="mt-2 mb-5 text-slate-400 text-center">
          You don't have permission to access requested page
        </p>
        <Button label="Go Back" onClick={() => navigate(-1)} />
      </div>
      <div className="hidden lg:flex lg:flex-col items-center justify-center">
        <img src={AccessDenied} className="w-[70%]" alt="access denied" />
      </div>
    </div>
  );
};
