import React, { FC } from "react";

export const SettlementIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.50015 8.33348C7.27913 8.33348 7.06717 8.42128 6.91089 8.57756C6.75461 8.73384 6.66681 8.9458 6.66681 9.16681V12.5001H8.33348V10.0001H10.8335V12.0835L13.7501 9.16681L10.8335 6.25015V8.33348H7.50015ZM10.5893 1.16098L18.8393 9.41098C18.9955 9.56725 19.0833 9.77918 19.0833 10.0001C19.0833 10.2211 18.9955 10.433 18.8393 10.5893L10.5893 18.8393C10.433 18.9955 10.2211 19.0833 10.0001 19.0833C9.77918 19.0833 9.56725 18.9955 9.41098 18.8393L1.16098 10.5893C1.00476 10.433 0.916992 10.2211 0.916992 10.0001C0.916992 9.77918 1.00476 9.56725 1.16098 9.41098L9.41098 1.16098C9.56725 1.00476 9.77918 0.916992 10.0001 0.916992C10.2211 0.916992 10.433 1.00476 10.5893 1.16098Z"
        fill={className}
      />
    </svg>
  );
};
