import { makerCheckerInstance } from '../../axios/axiosInstance'

export const createMakerChecker = async (payload: any) => {
  const response = await makerCheckerInstance.post("/MakerChecker/CreateMakerChecker", payload);
  return response;
};

export const searchMakerChecker = async (payload: any) => {
  const response = await makerCheckerInstance.post("/MakerChecker/SearchMakerChecker",payload);
  return response;
};

export const approveMakerChecker = async (payload: any) => {
  const response = await makerCheckerInstance.post("/MakerChecker/ApproveMakerChecker", payload);
  return response;
};

export const rejectMakerChecker = async (payload: any) => {
  const response = await makerCheckerInstance.post("/MakerChecker/RejectMakerChecker", payload);
  return response;
};