import React, { FC } from "react";

export const FlyIcon: FC<{ className?: string; fill?: string }> = ({
  className = "stroke-black-900",
  fill,
}) => {
  return (
    <svg
      width="35"
      height="29"
      viewBox="0 0 35 29"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.4634 13.3868L7.94669 1.60015L11.165 0.736816L22.75 11.4368L31.52 9.08682C32.1605 8.91531 32.8429 9.00526 33.4171 9.33689C33.9913 9.66852 34.4102 10.2147 34.5817 10.8551C34.7532 11.4956 34.6632 12.178 34.3316 12.7522C34 13.3264 33.4539 13.7453 32.8134 13.9168L7.86003 20.6001L6.56669 15.7702L6.96836 15.6618L11.08 19.7368L6.70336 20.9101C6.34447 21.0064 5.96371 20.9799 5.62158 20.835C5.27945 20.69 4.99557 20.4349 4.81503 20.1101L0.443359 12.2335L2.85836 11.5868L6.96836 15.6618L15.4634 13.3851V13.3868ZM4.66669 25.6668H31.3334V29.0001H4.66669V25.6668Z"
        fill={className}
      />
    </svg>
  );
};
