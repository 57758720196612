import { motion } from "framer-motion";
import { Tab } from "../../../components";
import Landing from "../../../components/BillableFlight/Landing/Landing";
import Parking from "../../../components/BillableFlight/Parking/Parking";
const BillableFlight = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-wrap "
    >
      <Tab
        className="sticky top-[65px] xl:top-[72px] pl-7 z-10"
        tabs={[
          {
            title: "Landing",
            render: () => <Landing />,
          },
          {
            title: "Parking",
            render: () => <Parking />,
          },
        ]}
      />
    </motion.div>
  );
};

export default BillableFlight;
