import React, { FC } from "react";

export const AccountIcon: FC<{ className?: string }> = ({
  className = "stroke-black-900",
}) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.6667 17.3332H1.33333C1.11232 17.3332 0.900358 17.2454 0.744078 17.0891C0.587797 16.9328 0.5 16.7209 0.5 16.4998V1.49984C0.5 1.27882 0.587797 1.06686 0.744078 0.910582C0.900358 0.754301 1.11232 0.666504 1.33333 0.666504H14.6667C14.8877 0.666504 15.0996 0.754301 15.2559 0.910582C15.4122 1.06686 15.5 1.27882 15.5 1.49984V16.4998C15.5 16.7209 15.4122 16.9328 15.2559 17.0891C15.0996 17.2454 14.8877 17.3332 14.6667 17.3332ZM3.83333 3.99984V7.33317H7.16667V3.99984H3.83333ZM3.83333 8.99984V10.6665H12.1667V8.99984H3.83333ZM3.83333 12.3332V13.9998H12.1667V12.3332H3.83333ZM8.83333 4.83317V6.49984H12.1667V4.83317H8.83333Z"
        fill={className}
      />
    </svg>
  );
};
