import { FC, ReactNode } from "react";

export interface InputData {
  placeholder?: string;
  value?: string;
  handleChange?: any;
  loading?: boolean;
  name?: string;
  register?: any;
  type?: any;
  pattern?: any;
  readOnly?: boolean;
  disabled?: boolean;
  min?: number | string;
  className?: any;
  containerClass?: any;
  labelClass?: string;
  handleBlur?: any;
  borderColor?: string;
  label?: ReactNode;
  error?: any;
}

export const Input: FC<InputData> = ({
  borderColor = "border-gray-400",
  className,
  containerClass,
  labelClass,
  placeholder,
  value,
  handleBlur,
  handleChange,
  loading = false,
  disabled = false,
  name,
  readOnly = false,
  register,
  pattern,
  min,
  type = "text",
  label,
  error,
}) => {
  return (
    <div
      className={`w-full flex flex-col justify-start relative  ${containerClass}`}
    >
      {typeof label === "string" ? (
        <label
          className={`w-full text-black-900  font-semibold text-xs xl:text-[13px] ml-2 ${labelClass}`}
          htmlFor={name}
        >
          {label}
        </label>
      ) : (
        label
      )}

      <input
        className={`w-full h-[40px] xl:h-[50px] rounded-[10px] text-xs mt-1 focus:outline-none focus:ring-green-600 invalid:border-red-900 focus:border-none ${borderColor}  ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={loading || disabled}
        name={name}
        id={name}
        {...register}
        type={type}
        pattern={pattern}
        readOnly={readOnly}
        onBlur={handleBlur}
        min={min}
        onFocus={(e) => e.target.removeAttribute("readonly")}
      />
      {error && (
        <div className="text-[11px] text-red-900 mt-1">{error?.message}</div>
      )}
    </div>
  );
};
